import React, { Fragment } from 'react'
import { Marker, Popup } from 'react-leaflet';
import L from 'leaflet';


const VenueLocationIcon = L.icon({
  iconUrl: require('./assets/venue_location_icon.png'),
  iconRetinaUrl: require('./assets/venue_location_icon.png'),
  iconAnchor: null,
  shadowUrl: null,
  shadowSize: null,
  shadowAnchor: null,
  iconSize: [21, 34],
  className: 'leaflet-venue-icon'
});


const VenueMarkers = (props) => {
  const { venues } = props;
  const { map } = props;

  let activeMarker = undefined
  const handleActiveMarker = (marker, location) => {
    //console.log("activemarker changed");
    if (marker === activeMarker) {
      return undefined;
    }
    activeMarker = marker;
    //console.log(activeMarker);
    map.setState({
      location: location
    });
  };

  const markers = venues.map((venue, index) => (
    (venue.latitude != null && venue.longitude != null) &&
    <Marker key={index}
      name={venue.name}
      position={[venue.latitude, venue.longitude]}
      icon={VenueLocationIcon}
      onClick={() => handleActiveMarker(index, venue)} >
      <Popup>
        <div >
          <b>{venue.name}</b>
        </div>
      </Popup>
    </Marker>
  ));

  return <Fragment>{markers}</Fragment>
};

export default VenueMarkers;