import React, { Component } from 'react';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import SubHead from '../../../SubHead/SubHead';
import Loading from '../../../Loading/Loading';
import { fetchPersonByID } from '../../actions';
import PersonBody from './PersonBody';


class ViewPersonDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  rec: false,
		  copied: false
		};
	}

	async componentDidMount() {
		//console.log(this.props.match.params)
		await this.props.fetchPersonByID(this.props.match.params.id)
		if (this.state.rec !== true){
			this.setState({
				rec: true
			});
		}
	}

	renderTemplate(person) {
		//console.log("PERSON")
		//console.log(person)
	
		return(
			<div>
				<SubHead tblabel={'Persons'} tb={'persons'} text="View Detail"  />
				<div className="mr-4 ml-4">
					<Row className="mt-2">
						<Col sm="8">
							<Card className="card-customized">
								<CardBody>
									<PersonBody person={ person } />
								</CardBody>
							</Card>
						</Col>
						
						<Col sm="4">
							<h2 style={{fontWeight: 'bold', color: '#6b1e29'}}>
								NOTAE Per { person.id }
							</h2>
							<div className="text-secondary d-flex justify-content-between ml-auto">
								<div>
									<CopyToClipboard 
									text={ 'https://www.notae-erc.eu/search/person/' + person.id }
									onCopy={() => { this.setState({copied: true}); window.setTimeout(()=>{ this.setState({copied: false}); }, 2000); } }>
										<Button size="sm" color={ this.state.copied ? 'primary': 'secondary'}>
											<FontAwesomeIcon icon="clone" /> <span className="d-none d-sm-inline">
											{this.state.copied ? 'Link copied' : 'https://www.notae-erc.eu/search/person/' + person.id }
											</span>
										</Button>
									</CopyToClipboard>
								</div>
							</div>

							<br/><br/>

							<h4 style={{color: '#6b1e29'}}>
								Links
							</h4>
							
							<div>
							{ 	//Trismegistos link(s)
								person.trismegistosID !== null &&
								person.trismegistosID.split('||^||').filter(Boolean).map( (v, k) => {
								//console.log(v)
								return 	<a key={k} href={ v.trim() } target="_blank" rel="noopener noreferrer">
											<FontAwesomeIcon icon="external-link-alt" /> TM number {v.split('/').pop()}
										</a>
							}) }
							</div>
							
						</Col>
					</Row>
				</div>
			</div>
		)
	}
	
	render() {
		if (!this.state.rec) {
			return <div className="m-5 p-5"><Loading /></div>;
		}

		return (
			<div className="mb-5">
			{ this.renderTemplate(this.props.person.result.person) }
			</div>
		);
	}
}

ViewPersonDetail.propTypes = {
	person: PropTypes.object.isRequired, 
	fetchPersonByID: PropTypes.func.isRequired
}

function mapStateToProps(state) {
	return {
		person: state
	}
}

export default connect(mapStateToProps, { fetchPersonByID })(ViewPersonDetail);