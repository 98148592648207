import React, { useState } from 'react';
import { NavLink, Navbar, Nav, NavItem, NavbarBrand, NavbarToggler, Collapse } from 'reactstrap';
import { NavLink as RRNavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import notaelogo from '../Home/NOTAE_Logo.png'

export default function FixedNavbar(props) {

  const [isOpen, toggleOpen] = useState(false);

  return (
    <div>
      <Navbar color="dark" dark expand="md" className="fixed-top">
        <NavbarBrand className="img-container">
          <img alt="" src={notaelogo} width='70px' style={{backgroundColor: '#fff'}}/>
        </NavbarBrand>

        <NavbarToggler onClick={()=>{ toggleOpen(!isOpen) }} />
        
        <Collapse isOpen={ isOpen } navbar>
          <Nav navbar>
            <NavItem className="link-navbar" style={{fontSize:'20px', color:"light"}}>
              <NavLink to="/home" tag={RRNavLink}>HOME</NavLink>
            </NavItem>
            <NavItem className="link-navbar" style={{fontSize:'20px'}}> {/*, backgroundColor: '#6b1e29', color: '#fff'*/}
              <NavLink to="/graphicsymbols" tag={RRNavLink}>Graphic Symbols</NavLink>
            </NavItem>
            <NavItem className="link-navbar" style={{fontSize:'20px'}}>
              <NavLink to="/documents" tag={RRNavLink}>Documents</NavLink>
            </NavItem>
            <NavItem className="link-navbar" style={{fontSize:'20px'}}>
              <NavLink to="/persons" tag={RRNavLink}>Persons</NavLink>
            </NavItem>
            <NavItem className="link-navbar" style={{fontSize:'20px'}}>
              <NavLink to="/casestudies" tag={RRNavLink}>Case Studies</NavLink>
            </NavItem>
            <NavItem>
              <NavLink className="link-navbar" style={{ fontSize:'20px'}} to="/map" tag={RRNavLink}>
                <FontAwesomeIcon icon="earth" /> Map
              </NavLink>
            </NavItem>
          </Nav>
          
          <Nav className="ml-auto" navbar>
            <NavItem className="link-navbar" style={{fontSize:'20px'}}>
              <NavLink href="https://notae-project.digilab.uniroma1.it/" target="_blank" rel="noopener noreferrer" title="NOTAE's website">
                Website
              </NavLink>
            </NavItem>
            <NavItem className="link-navbar" style={{fontSize:'20px'}}>
              <NavLink to="/api" tag={RRNavLink}>Data Export</NavLink>
            </NavItem>
          </Nav>
        </Collapse>
      </Navbar>
    </div>
  );
}
