import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import FieldSet from '../FieldSet';
import {estraiSiglaAutore} from '../Utils/utils'


export default class PersonBody extends Component {

  constructor(props) {
    super();
    this.state = {
      valHand: props.valHand
    }
  }

  _changeValSelect = (v) =>{
    this.setState({
      valHand: v.constructor === Object && v.value ? v.value : v
    });
  }

  changeStateValHands = (v) =>{
    this.setState({
      valHand: v
    });
  }
  

  render() {
    //console.log(this.props)
    var person = this.props.person;
    //console.log(person)

    // set up "person.authors" considering also "other authors"
    var personAuthors = "<p>" + estraiSiglaAutore(person.userCreation) + "</p>";
    if (person.otherAuthors !== "" && person.otherAuthors !== null){
      var others = person.otherAuthors.split("||^||").filter(Boolean);
      others.forEach(author => {
        personAuthors = personAuthors + "<p>" + estraiSiglaAutore(author) + "</p>"
      });
    }

    // set up "gender" field
    var pGender = ""
    if (person.gender !== null && person.gender !== undefined){
      pGender = person.gender.replace("Zero", "unknown") //replace 'Zero' value for unknown gender
    }

    // set up graphic symbols IDs
    var personDocs = ""
    var docs = person.personDocs.split('; ').filter(Boolean);
    //console.log(docs)
    docs.sort((a, b) => a.localeCompare(b, undefined, { numeric: true })) // sort array of integers represente as strings
    docs.forEach((docId, index) => {
      personDocs = personDocs + "<a href= \"/search/document/" + docId + "\"  target=\"_blank\" rel=\"noopener noreferrer\">"
                                  + "<FontAwesomeIcon icon=\"external-link-alt\" /> Doc " + docId + "</a>; "
    });
    personDocs = personDocs.substring(0, personDocs.length-2); //remove last semicolon from the list


    return (
      <div>
        <FieldSet legend="Main Informations" fields={[
          ["Name", person.name],
          ["Gender", pGender],
          ["Place of activity", person.placesOfActivity],
          ["Status", person.status],
          ["Roles", person.personRoles]
        ]} />
        
        {//Person's Resources
          <fieldset className="p-3 border border-white mt-3">
            <legend 
              style={{ fontSize: '1.3rem', 'fontWeight': 'bold', color:'#822333'}}>Prosopographical and bibliographical resources</legend>
            <Row className={"border-bottom mt-2 mb-2 pb-1"}>
              <Col>
                <div><label dangerouslySetInnerHTML={{ __html: person.prosographicalResources }}></label></div>
              </Col>
            </Row>
          </fieldset>
        }

        {// Documents related to this person
          <FieldSet legend="Documents related to this person" fields={[
            personDocs !== "" && ["Documents", personDocs]
          ]} />
        }

        {//Person's Comments
          <fieldset className="p-3 border border-white mt-3">
            <legend style={{ fontSize: '1.3rem', 'fontWeight': 'bold', color:'#822333'}}>Comments</legend>
            <Row className={"border-bottom mt-2 mb-2 pb-1"}>
              <Col>
                <div><label dangerouslySetInnerHTML={{ __html: person.comment }}></label></div>
              </Col>
            </Row>
          </fieldset>
        }

        <FieldSet legend="Metadata" fields={[
          ["Author(s)", personAuthors],
          // ["Status", person.state],
          ["Last Modified", person.monthLastUpdate.split('T')[0]]
        ]} />
      </div>
    );
  }
}