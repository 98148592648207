import React from "react";
import imgNOTAELogo from './NOTAE_Logo.png';

export default function BrowserNotSupported(props) {

  const compatibility = props.compatibility;
  const browser = props.browser;

  return (
    <div className="container text-center">
      <div className="my-5 py-5">
        <h1>Your browser is not supported.</h1>
        <p>Browser: <code>{ browser.name }</code>, version <code>{ browser.version }</code> on <code>{ browser.os }</code>.</p>

        <div>
          <h4>List of compatible browsers</h4>
          <pre>
          {
            Object.keys(compatibility).map((i, k)=>{
              return (
                <div key={k}>
                  {
                    (compatibility[i] !== 10000 ? i + ' >= ' + compatibility[i] : '' )
                  }
              </div>
              );
            })
          }
          </pre>
        </div>

        <div className="border-top pt-5 mt-5">
          <img className="img-fluid shadow-lg" src={ imgNOTAELogo } />
        </div>
      </div>

    </div>
  );
}
