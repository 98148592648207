import React, { Component } from 'react';
import { Navbar, Nav, NavItem, NavLink, Col, Row } from 'reactstrap';
import "bootstrap/dist/css/bootstrap.css";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './SubHead.css'
import graphicsymbolsImg from '../Intro/graphicsymbols/gs.jpg'
import DocumentsImg from '../Intro/documents/doc.jpg';
import PersonsImg from '../Intro/persons/pers.jpg';
import CaseStudiesImg from '../Intro/documents/doc.jpg';


class SubHead extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showSaved: false
    };
  }

  

  render(){
    const tblabel = this.props.tblabel;
    const tb = this.props.tb;
    const text = this.props.text ? <span className="subText">{this.props.text}</span> : false;
    const dataMap = {
      graphicsymbols: {
        img: graphicsymbolsImg
      },
      documents: {
        img: DocumentsImg
      },
      persons: {
        img: PersonsImg
      },
      casestudies: {
        img: CaseStudiesImg
      }
    };
    
    const d = dataMap[tb];

    let menuItems = [
      {
        to: '/search/' + tb + '/all',
        icon: 'list-ul',
        text: 'Show all'
      },
      {
        to: '/search/' + tb,
        icon: 'search',
        text: 'Search'
      }
    ];


    return (
      <div style={{backgroundImage: 'url('+d.img+')', backgroundSize: 'contain'}}>
        <div className="jumbotron p-4">
          <div className="container">
            <Row>
              <Col sm='9'>
                <h2 className = "textstyle" style={{fontWeight: 'bold', color: '#6b1e29'}}>{tblabel}</h2> 
                <h3 className = "textstyle">{ text }</h3>
              </Col>
              <Col>
                <Navbar light expand="xs" className="ml-auto mt-3">
                  <Nav className="ml-auto" navbar>
                    {
                      menuItems.map( (e, k) => {
                        return (
                          <div key={k} className='myButton ml-3'>
                            <NavItem key={k} >
                              <NavLink to={e.to} tag={Link} style={{fontSize: '1.1rem', color: 'black'}}>
                                <FontAwesomeIcon icon={e.icon} /> <span className="d-none d-md-inline">{e.text}</span>
                              </NavLink>
                            </NavItem>
                          </div>
                        );
                      })
                    }
                  </Nav>
                </Navbar>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    );
  }
}

export default SubHead;