import React, { Component } from 'react';
import { Row, Col} from 'reactstrap';


export default class FieldSet extends Component {

  render() {
    const fields = this.props.fields;
    const legend = this.props.legend;
    //console.log("FIELDS")
    //console.log(fields)

    return (
      <fieldset className="p-3 border border-white mt-3">
        { //header
          legend && <legend style={{ fontSize: '1.3rem', 'fontWeight': 'bold', color:'#822333'}}>{ legend }</legend> 
        }  
        { //fields
          fields.map((f,k) => {
            const name = f[0];
            const value = f[1];
            //console.log("nome:"+name);
            //console.log("valore:"+value);
            return(
              <Row key={k} className={"border-bottom mt-2 mb-2 pb-1"}>
                <Col sm="4" md="3">
                  <div><label className="font-weight-bold">{name}</label></div>
                </Col>
                <Col>
                  <div><label dangerouslySetInnerHTML={{__html: value}}></label></div>
                </Col>
              </Row>
            )
          })
        }
      </fieldset>
    );
  }
}