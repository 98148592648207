import React, { Component } from 'react';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import SubHead from '../../../SubHead/SubHead';
import Loading from '../../../Loading/Loading';
import { fetchGSByID } from '../../actions';
import GSBody from './GSBody';
import PreviewModal from '../Utils/PreviewModal';
import PreviewModalHidden from '../Utils/PreviewModalHidden';


class ViewGSDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  rec: false,
		  copied: false
		};
	}

	async componentDidMount() {
		//(this.props.match.params)
		await this.props.fetchGSByID(this.props.match.params.id)
		if (this.state.rec !== true){
			this.setState({
				rec: true
			});
		}
	}

	renderTemplate(gs) {
		//console.log("GS")
		//console.log(gs)

		// set up GS's images
		let gsImage = ""
		let gsImageENHANCED = ""
		if (gs.picture !== undefined && gs.picture !== null ){
			let files = Object.values(gs.picture) || [];
			let pictureSplit = gs.picture.split(';base64,')
			gsImage = pictureSplit[0] + ';base64,' + gs.file
			if (gs.fileENHANCED !== undefined){
				gsImageENHANCED = pictureSplit[0] + ';base64,' + gs.fileENHANCED
			}
		}

		return(
			<div>
				<SubHead tblabel={'Graphic Symbols'} tb={'graphicsymbols'} text="View Detail"  />
				<div className="mr-4 ml-4">
					<Row className="mt-2">
						<Col sm="8">
							<Card className="card-customized">
								<CardBody>
									<GSBody gs={ gs } />
								</CardBody>
							</Card>
						</Col>
						
						<Col sm="4">
							<h2 style={{fontWeight: 'bold', color: '#6b1e29'}}>
								NOTAE GS { gs.id }
							</h2>
							<div className="text-secondary d-flex justify-content-between ml-auto">
								<div>
									<CopyToClipboard 
									text={ 'https://www.notae-erc.eu/search/graphicsymbol/' + gs.id }
									onCopy={() => { this.setState({copied: true}); window.setTimeout(()=>{ this.setState({copied: false}); }, 2000); } }>
										<Button size="sm" color={ this.state.copied ? 'primary': 'secondary'}>
											<FontAwesomeIcon icon="clone" /> <span className="d-none d-sm-inline">
											{this.state.copied ? 'Link copied' : 'https://www.notae-erc.eu/search/graphicsymbol/' + gs.id}
											</span>
										</Button>
									</CopyToClipboard>
								</div>
							</div>

							<br/><br/>

							
							<h4 style={{color: '#6b1e29'}}>
								Links
							</h4>
							
							<div>
								<a href={ "/search/document/"+gs.docId } target="_blank" rel="noopener noreferrer">
									<FontAwesomeIcon icon="external-link-alt" /> Document {gs.docId}
								</a>
								<br/>
								<a href={ "/search/person/"+gs.handPersonId } target="_blank" rel="noopener noreferrer">
									<FontAwesomeIcon icon="external-link-alt" /> Person {gs.handPersonId}
								</a>
							</div>
							{ gs.picture !== undefined && gs.picture !== null &&
								<div className="mt-3 image-preview">
									<PreviewModal file={ gsImage } description={"Graphic symbol " + gs.id} />
								</div>
							}
							{ gs.fileENHANCED !== undefined &&
								<div>
									<PreviewModalHidden file={ gsImageENHANCED } description={"Graphic symbol " + gs.id + " (enhanced version)"} />
								</div>
							}
						</Col>
					</Row>
				</div>
			</div>
		)
	}
	  
	
	render() {
		if (!this.state.rec) {
			return <div className="m-5 p-5"><Loading /></div>;
		}

		return (
			<div className="mb-5">
				{ this.renderTemplate(this.props.gs.result) }
			</div>
		);
	}
}

ViewGSDetail.propTypes = {
	gs: PropTypes.object.isRequired, 
	fetchGSByID: PropTypes.func.isRequired
}

function mapStateToProps(state) {
	return {
		gs: state
	}
}

export default connect(mapStateToProps, { fetchGSByID })(ViewGSDetail);