import React, { Component } from 'react';
import { Alert } from 'reactstrap';
import Loading from '../../../Loading/Loading';
import { fetchGraphicSymbols } from '../../actions';
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DataTable from "react-data-table-component";
import PropTypes from 'prop-types';
import { connect } from 'react-redux';


class GSSearchResults extends Component {

  constructor(props) {
    super(props);
    this.state = {
      result: false
    };
  }


	async _fetchData() {
    this.setState({
      result: false
    });
		await this.props.fetchGraphicSymbols(this.props.val, 'id', 'asc',  20 , 0);
    this.setState({
      result: true
    });
	}

  componentDidMount(){
    this._fetchData();
  }

  componentDidUpdate(prevProps) {
    if (
      this.props.page !== prevProps.page
      || this.props.fld !== prevProps.fld
      || this.props.val !== prevProps.val
      || this.props.query !== prevProps.query
    ) {
      this._fetchData();
    }
  }

	render() {
    var graphicsymbols = this.props.graphicsymbols.result.graphicSymbols
		//console.log(this.props.graphicsymbols.result.graphicSymbols)
		//console.log(this.props.graphicsymbols.result.total)
		var totalRows = this.props.graphicsymbols.result.total
		
		var columns = []
		var grid = null

    if (!this.state.result) {
      return <div className="container"><Loading /></div>;
    }

    if(this.state.result.type === 'error') {
      return (
        <div>
          <div className="container">
            <Alert color="danger" className="mt-5">
              <h4 className="alert-heading">Error! Contact the system administrator if the error persists.</h4>
            </Alert>
          </div>
        </div>
      )
    }

    if(totalRows === 0) {
      return (
        <div>
            <div className="container">
              <Alert color="warning">
                  <h4 className="alert-heading">No result found.</h4>
              </Alert>
            </div>
        </div>
      )
    }


    columns = [
        { selector: (row,i) => row.id, name: 'ID' , /*center: true*/ width: '10%'},
        { selector: (row,i) => row.principalIdentifier, name: 'Main Identifier', width: '30%' },
        { selector: (row,i) => row.shape, name: 'Shape', width: '30%' },
        { cell: row => 
            <Button size="sm" color="primary" style={{ marginLeft: "auto", width: "70px" }} tag={Link} to={ '/search/graphicsymbol/' + [row.id].join('/') } target="_blank">
                Details <FontAwesomeIcon icon="fas fa-angle-double-right" />
              </Button>
          }
    ];
  
    const dataTableStyle = {
      head: {
        style: {
          fontSize: '14px',
          fontWeight: "bold",
          color: '#822333',
        },
      },
      // headRow: {
      // 	style: {
      // 		backgroundColor: '#c9a0a7',
      // 		minHeight: '52px'
      // 	},
      // },
    };

      var curr_page = 1
      var pageLimit = 20

      const handlePageChange = page => {
        this.props.fetchGraphicSymbols(this.props.val, 'id', 'asc',  20 , page - 1);
        curr_page = page;
      };
    
      const handlePerRowsChange = async (newPerPage, page) => {
        this.props.fetchGraphicSymbols(this.props.val, 'asc',  newPerPage , page - 1);
        pageLimit = newPerPage;
      };

      grid = (<div>
                <div className="mb-3">
                  Graphic Symbols found: <span style={{color: "#822333", fontWeight: "bold"}}>
                  { totalRows }</span> 
                </div>
                <div style={{width: '100%' }}>
                  <DataTable
                    striped
                    headerColor="green"
                    columns={columns}
                    data={graphicsymbols}
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    paginationDefaultPage={curr_page}
                    paginationPerPage={20}
                    paginationRowsPerPageOptions={[5,20,50]}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    customStyles={dataTableStyle}
                  />
                </div>
              </div>
      )

		
		const emptyMessage = (
			<div className='ui info message'> There are no graphic symbols</div>
		)
	
    
    return (
      <div >
        <div className='ui container'>
          {totalRows > 0 ? grid : emptyMessage}
        </div>
      </div>
    ); 
	}
}

GSSearchResults.propTypes = {
	graphicsymbols: PropTypes.object.isRequired, 
	fetchGraphicSymbols: PropTypes.func.isRequired
}

function mapStateToProps(state) {
	//console.log(state)
	return {
		graphicsymbols: state
	}
}

export default connect(mapStateToProps, { fetchGraphicSymbols })(GSSearchResults);
