import React from "react";
import imgNotaeLogo from './NOTAE_Logo.png';
import ReactMarkdownPath from '../ReactMarkdownPath/ReactMarkdownPath';
import intro from './IntroMd.md';
import "bootstrap/dist/css/bootstrap.css";
import './home.css'


export default function Home(){
   const titles = [
     {
       id    : 'intro',
       title : "The NOTAE System: Introduction",
       path  : intro
     }
   ];

   
   const showContent = function(){
     return (
       <div style={{ backgroundColor: '#ffffffc3'}} className="pl-5 pr-5 pt-1 pb-4 " >
         { titles.map( (k, i) =>{
           return (
             <div key={i} className="text-justify mt-4 textstyle" style={k.style}>
               <ReactMarkdownPath path={k.path} showAccessDate={true} />
             </div>
           );
         })}
       </div>
     );
   }

   return (
     <div className="image1">
       <div className="container pt-1 pb-1 " >
         <div className="bg-light">
           { showContent() }
         </div>
       </div>
     </div>
   );
}
