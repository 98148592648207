import React, { Component } from 'react';
import { Card, CardImg, Modal, ModalFooter, ModalBody, CardBody } from 'reactstrap';


export default class PreviewModal extends Component {

  constructor(props) {
    super(props);
    this.state = {
      modal: false
    };

    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      modal: !this.state.modal
    });
  }

  render(){

    return (
      <div>
        <Card className="mt-1">
          <CardImg top
            width="100%"
            src={this.props.file}
            onClick={this.toggle} />
          <CardBody className="text-secondary" 
            style={{ fontSize: '.9rem', fontStyle: 'italic'}}>{this.props.description}
          </CardBody>
        </Card>

        <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className} size="lg">
          <ModalBody>
            <div>
              <img style={ {width: "100%"} }
                  src={this.props.file}
                  alt={ this.props.description } />
            </div>
          </ModalBody>
          <ModalFooter>
            <CardBody className="text-secondary" 
              style={{ fontSize: '.9rem', fontStyle: 'italic'}}>{this.props.description}
            </CardBody>
          </ModalFooter>

        </Modal>
      </div>
    );
  }
}