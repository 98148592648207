import React from 'react';
import { Row, Col } from 'reactstrap';
import Erc from './img/erc.jpg';
import Sapienza from './img/sapienza.jpg';

export default function Footer () {

  return (
    <footer className="mt-5 text-white p-3" style={{background: '#822333'}}>
      <div className="container medium">
        <Row className="my-3">
          
          <Col xs={8}>
            <p><a href="https://notae-erc.eu/" target="_blank" rel="noopener noreferrer" title="NOTAE System">NOTAE System</a> © 2024 by <a href="https://notae-project.digilab.uniroma1.it/" target="_blank" rel="noopener noreferrer" title="NOTAE-erc">NOTAE Team</a> is licensed under <a href="https://creativecommons.org/licenses/by-nc-sa/4.0/?ref=chooser-v1" target="_blank" rel="noopener noreferrer" title="NOTAE-erc">
              CC BY-NC-SA 4.0
              <img style={{ height:'22px', marginLeft:'3px', verticalAlign:'text-bottom' }} src="https://mirrors.creativecommons.org/presskit/icons/cc.svg?ref=chooser-v1" alt=""/>
              <img style={{ height:'22px', marginLeft:'3px', verticalAlign:'text-bottom' }} src="https://mirrors.creativecommons.org/presskit/icons/by.svg?ref=chooser-v1" alt=""/>
              <img style={{ height:'22px', marginLeft:'3px', verticalAlign:'text-bottom' }} src="https://mirrors.creativecommons.org/presskit/icons/nc.svg?ref=chooser-v1" alt=""/>
              <img style={{ height:'22px', marginLeft:'3px', verticalAlign:'text-bottom' }} src="https://mirrors.creativecommons.org/presskit/icons/sa.svg?ref=chooser-v1" alt=""/>
            </a></p>

            <p className="left">
            This project has received funding from the <a href="https://erc.europa.eu/homepage" target="_blank" rel="noopener noreferrer" title="NOTAE website">European Research Council (ERC)</a> under the European Union’s Horizon 2020 research and innovation programme (grant agreement No. 786572-NOTAE)
            </p>
            {/* <p> Principal Investigator: <a href="antonella.ghignoli@uniroma1.it" target="_blank" rel="noopener noreferrer" title="NOTAE website">
              Antonella Ghignoli
              </a>
            </p> */}
            <p> NOTAE Website: <a href="https://notae-project.digilab.uniroma1.it/" target="_blank" rel="noopener noreferrer" title="NOTAE website">
              notae-project.digilab.uniroma1.it
              </a>
            </p>  
          </Col>

          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <img src={Sapienza} alt="Sapienza Università di Roma" className="img-fluid" width='150px'/>
          </Col>

          <Col style={{ display: 'flex', alignItems: 'center' }}>
            <img src={Erc} alt="European Research Council" className="img-fluid" width='150px'/>
          </Col>

        </Row>
      </div>
    </footer>
    
  );
}
