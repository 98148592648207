/* jshint esversion: 6 */
import React from "react";
import ReactMarkdownPath from '../ReactMarkdownPath/ReactMarkdownPath';
import SubHead from '../SubHead/SubHead';
import graphicsymbolsMd from './graphicsymbols/graphicsymbols.md'
import graphicsymbolsImg from './graphicsymbols/gs.jpg'
import DocumentsMd from './documents/documents.md';
import DocumentsImg from './documents/doc.jpg';
import PersonsMd from './persons/persons.md';
import PersonsImg from './persons/pers.jpg';
import CaseStudiesMd from './casestudies/casestudies.md';
import CaseStudiesImg from './documents/doc.jpg';
import './Intro.css';
import immagineprova from '../Home/imm1.jpg'


export default function Intro(props){

  const tb = props.match.params.table;

  const dataMap = {
    graphicsymbols: {
      label: "Graphic Symbols",
      img: graphicsymbolsImg,
      data: [{
        title: false,
        mdPath: graphicsymbolsMd
      }]
    },
    documents: {
      label: "Documents",
      img: DocumentsImg,
      data: [{
        title: false,
        mdPath: DocumentsMd
      }]
    },
    persons: {
      label: "Persons",
      img: PersonsImg,
      data: [{
        title: false,
        mdPath: PersonsMd
      }]
    },
    casestudies: {
      label: "Case Studies",
      img: CaseStudiesImg,
      data: [{
        title: false,
        mdPath: CaseStudiesMd
      }]
    }
  };


  if (!dataMap.hasOwnProperty(tb)) {
    return (<div className="container m-5 p-5 text-center text-danger">
      Error: invalid table name
      <code>{ tb }</code>
    </div>);
  }

  const d = dataMap[tb];

  return <div>
      <SubHead tblabel={d.label} tb={tb} text="Introduction" />
      <div className="container pt-2 pb-2 mb-3" >
        { d.data.map( (i, k) => {
          return (<div key={ k } className={`intro-level-${k} mt-5 mb-5`}>
            { i.title && <h1>{ i.title }</h1> }
            <div className="my-3 px-md-5 text-justify">
              <ReactMarkdownPath path={i.mdPath} showAccessDate={true} />
            </div>
          </div>)
        } )}
      </div>
    </div>
}
