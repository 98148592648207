import  React  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DataTable from "react-data-table-component";
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import SubHead from '../../SubHead/SubHead';
import { fetchPersons } from '../actions';


class PListPage extends React.Component {
	
	componentDidMount() {
		this.props.fetchPersons('', 'id', 'asc',  20 , 0);
	}

	render() {
		var persons = this.props.persons.result.persons
		//console.log(this.props.persons.result)
		const totalRows = this.props.persons.result.total
		
		var columns = []
		var grid = null

		if (totalRows > 0) {

			columns = [
				{ selector: (row,i) => row.id, name: 'ID', width: '100px'},
				{ selector: (row,i) => row.name, name: 'Name', width: '300px'},
				{ selector: (row,i) => row.placesOfActivity, name: 'Place of Activity', width: '300px' },
				{ cell: row => 
					<Button size="sm" color="primary" style={{ marginLeft: "auto", width: "70px" }} tag={Link} to={ '/search/person/' + [row.id].join('/') } target="_blank">
                    	Details <FontAwesomeIcon icon="fas fa-angle-double-right" /> 
                  	</Button>
				  }
		  	];
		
			const dataTableStyle = {
				head: {
					style: {
						fontSize: '14px',
						fontWeight: "bold",
						color: '#822333',
					},
				},
				// headRow: {
				// 	style: {
				// 		backgroundColor: '#c9a0a7',
				// 		minHeight: '52px'
				// 	},
				// },
			};

			var curr_page = 1
			var pageLimit = 20

			const handlePageChange = page => {
				this.props.fetchPersons('', 'id', 'asc',  20 , page - 1);
				curr_page = page;
			};
			
			const handlePerRowsChange = async (newPerPage, page) => {
				this.props.fetchPersons('', 'id', 'asc',  newPerPage , page - 1);
				pageLimit = newPerPage;
			};

			grid = (<div>
						<div className="mb-3">
							Persons found: <span style={{color: "#822333", fontWeight: "bold"}}>
								{ totalRows }</span>
						</div>
						<div style={{ width: '100%' }}>
							<DataTable
								striped
								highlightOnHover
								columns={columns}
								data={persons}
								pagination
								paginationServer
								paginationTotalRows={totalRows}
								paginationDefaultPage={curr_page}
								paginationPerPage={20}
								paginationRowsPerPageOptions={[5,20,50]}
								onChangeRowsPerPage={handlePerRowsChange}
								onChangePage={handlePageChange}
								customStyles={dataTableStyle}
							/>
						</div>
					</div>
			)

		}

		const emptyMessage = (
			<div className='ui info message'> There are no persons</div>
		)

		return (
			<div >
				<SubHead tblabel={'Persons'} tb={'persons'} text="View all" />
				<div className='ui container'>
					{totalRows > 0 ? grid : emptyMessage}
				</div>
			</div>
		); 
	}
}

PListPage.propTypes = {
	persons: PropTypes.object.isRequired, 
	fetchPersons: PropTypes.func.isRequired
}

function mapStateToProps(state) {
	//console.log(state)
	return {
		persons: state
	}
}

export default connect(mapStateToProps, { fetchPersons })(PListPage);