import React from "react";
import ReactMarkdownPath from '../ReactMarkdownPath/ReactMarkdownPath';
import api from './Api.md';

export default function Api(){

  return (
    <div className="container">
      <div className="my-5 px-5">
        <ReactMarkdownPath path={api} showAccessDate={false} />
      </div>
    </div>
  );
}
