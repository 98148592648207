import  React  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DataTable from "react-data-table-component";
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import SubHead from '../../SubHead/SubHead';
import { fetchGraphicSymbols } from '../actions';


class GSListPage extends React.Component {
	
	componentDidMount() {
		this.props.fetchGraphicSymbols('', 'id', 'asc',  20 , 0);
	}

	render() {
		var graphicsymbols = this.props.graphicsymbols.result.graphicSymbols
		//console.log(this.props.graphicsymbols.result.graphicSymbols)
		//console.log(this.props.graphicsymbols.graphicsymbols.total)
		const totalRows = this.props.graphicsymbols.result.total
		
		var columns = []
		var grid = null

		if (totalRows > 0) {

			columns = [
				{ selector: (row,i) => row.id, name: 'ID' , /*center: true*/ width: '100px'},
				{ selector: (row,i) => row.principalIdentifier, name: 'Main Identifier', width: '300px' },
				{ selector: (row,i) => row.shape, name: 'Shape', width: '300px' },
				{ cell: row => 
					<Button size="sm" color="primary" style={{ marginLeft: "auto", width: "70px" }} tag={Link} to={ '/search/graphicsymbol/' + [row.id].join('/') } target="_blank">
                    	Details <FontAwesomeIcon icon="fas fa-angle-double-right" /> 
                  	</Button>
				}
		  	];

			const dataTableStyle = {
				head: {
					style: {
						fontSize: '14px',
						fontWeight: "bold",
						color: '#822333',
					},
				},
				// headRow: {
				// 	style: {
				// 		backgroundColor: '#c9a0a7',
				// 		minHeight: '52px'
				// 	},
				// },
			};
		
			var curr_page = 1
			var pageLimit = 20

			const handlePageChange = page => {
				this.props.fetchGraphicSymbols('', 'id', 'asc',  20 , page - 1);
				curr_page = page;
			};
			
			const handlePerRowsChange = async (newPerPage, page) => {
				this.props.fetchGraphicSymbols('', 'id', 'asc',  newPerPage , page - 1);
				pageLimit = newPerPage;
		  	};

			grid = (<div>
						<div className="mb-3">
							Graphic Symbols found: <span style={{color: "#822333", fontWeight: "bold"}}>
								{ totalRows }</span>
						</div>
						<div style={{ width: '100%' }}>
							<DataTable
								striped
								highlightOnHover
								columns={columns}
								data={graphicsymbols}
								pagination
								paginationServer
								paginationTotalRows={totalRows}
								paginationDefaultPage={curr_page}
								paginationPerPage={20}
								paginationRowsPerPageOptions={[5,20,50]}
								onChangeRowsPerPage={handlePerRowsChange}
								onChangePage={handlePageChange}
								customStyles={dataTableStyle}	
							/>
						</div>
					</div>
			)

		}

		const emptyMessage = (
			<div className='ui info message'> There are no graphic symbols</div>
		)
	
		return (
			<div >
				<SubHead tblabel={'Graphic Symbols'} tb={'graphicsymbols'} text="View all" />
				<div className='ui container'>
					{totalRows > 0 ? grid : emptyMessage}
				</div>
			</div>
		); 
	}
}

GSListPage.propTypes = {
	graphicsymbols: PropTypes.object.isRequired, 
	fetchGraphicSymbols: PropTypes.func.isRequired
}

function mapStateToProps(state) {
	//console.log(state)
	return {
		graphicsymbols: state
	}
}

export default connect(mapStateToProps, { fetchGraphicSymbols })(GSListPage);