import React, { useState, useEffect } from 'react'
import { FiChevronLeft  } from "react-icons/fi";
import { Sidebar, Tab } from 'react-leaflet-sidetabs'
import { Row, Col } from 'reactstrap';
import Select from 'react-select'
import axios from 'axios';
import { Button, ButtonGroup } from 'reactstrap'; 
import DataTable from "react-data-table-component";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import Loading from '../Loading/Loading';
import makeAnimated from 'react-select/animated';


const SidebarComponent = ({ map, location }) => {

   const [data1, setGraphicSymbols] = useState([]);
   const [data2, setDocuments] = useState([]);
   const [selectedTab, setSelectedTab] = useState('gs');
   const [isLoading, setIsLoading] = useState(false);

   const [gsCurrPage, setGsCurrPage] = useState(1);
   const [gsPageLimit, setGsPageLimit] = useState(20);

   const [docsCurrPage, setDocsCurrPage] = useState(1);
   const [docsPageLimit, setDocsPageLimit] = useState(20);

   const fetchGraphicSymbols = async (lid, filter, sortField, sortDirection, pageSize, pageIndex) =>{
      //var filter = ''; var sortField = 'id'; var sortDirection= 'asc'; var pageSize = 20 ; var pageIndex = 0
      try {
         const {data: response} = await axios.get('/api/locations/allGsFromLocId', {params: {lid:lid, filter:filter, sortingField:sortField, sortingOrder:sortDirection, limit:pageSize, offset:pageIndex*pageSize}})
         setGraphicSymbols(response)
         setIsLoading(false);
         //console.log(response)
      } catch(error) {
         //console.log(error);
      };
   };
   
   const fetchDocuments = async (lid, filter, sortField, sortDirection, pageSize, pageIndex) =>{
      // var filter = ''; var sortField = 'id'; var sortDirection= 'asc'; var pageSize = 20 ; var pageIndex = 0
      try {
         const {data: response} = await axios.get('/api/locations/allDocsFromLocId', {params: {lid:lid, filter:filter, sortingField:sortField, sortingOrder:sortDirection, limit:pageSize, offset:pageIndex*pageSize}})
         setDocuments(response)
         //console.log(response)
      } catch(error) {
         //console.log(error);
      };
   };

   useEffect(() => {
      if (location !== undefined){
         //console.log("location: " + location.name);
         setIsLoading(true);
         fetchGraphicSymbols( location.id,'', 'id', 'asc',  20 , 0);
         fetchDocuments( location.id,'', 'id', 'asc',  20 , 0);
      }
   }, [location]);

   const [openTab, setOpenTab] = useState('gs')

   const onClose = () => {
      setOpenTab(false)
   }

   const onOpen = id => {
      setOpenTab(id)
   }

   const _changeValSelect = (v) =>{
      //console.log(v)
      var selectedDates = ''
      if (v.length > 0){
         selectedDates = 'century||^||'
         v.forEach(date => {
            selectedDates = selectedDates + date.value + ';'
         });
      }
      setIsLoading(true);
      fetchGraphicSymbols( location.id, selectedDates, 'id', 'asc',  20 , 0);
      fetchDocuments( location.id, selectedDates, 'id', 'asc',  20 , 0);
   }


   // manage animations in select (multi options) component
   const animatedComponents = makeAnimated();

   var gsColumns = []
   var docsColumns = []
   var docsTotalRows = 0
   var gsTotalRows = 0
   var grid = null

   if (data1.result !== undefined && data2.result !== undefined) {
      //console.log(data1.result.graphicSymbols)
      var gsList = data1.result.graphicSymbols
      gsTotalRows = data1.result.total
      var docsList = data2.result.documents
      docsTotalRows = data2.result.total

      gsColumns = [
         { selector: (row,i) => row.id, name: 'ID' , /*center: true*/ width: '100px'},
         { selector: (row,i) => row.principalIdentifier, name: 'Main Identifier', width: '150px' },
         { selector: (row,i) => row.dateNotes, name: 'Date', width: '150px' },
         { cell: row => 
            <Button size="sm" color="primary" style={{ marginLeft: "auto", width: "70px" }} tag={Link} to={ '/search/graphicsymbol/' + [row.id].join('/') } target="_blank">
               Details <FontAwesomeIcon icon="fas fa-angle-double-right" /> 
            </Button>
         }
      ];

      docsColumns = [
         { selector: (row,i) => row.id, name: 'ID' , /*center: true*/ width: '100px'},
         { selector: (row,i) => row.mainIdentifier, name: 'Main Identifier', width: '150px' },
         { selector: (row,i) => row.dateNotes, name: 'Date', width: '150px' },
         { cell: row => 
            <Button size="sm" color="primary" style={{ marginLeft: "auto", width: "70px" }} tag={Link} to={ '/search/document/' + [row.id].join('/') } target="_blank">
					Details <FontAwesomeIcon icon="fas fa-angle-double-right" /> 
				</Button>
         }
      ];

      const dataTableStyle = {
         head: {
            style: {
               fontSize: '12px',
               fontWeight: "bold",
               color: '#822333',
            },
         },
         headRow: {
            style: {
               minHeight: '40px',
            },
         },
         rows: {
            style: {
               fontSize: '11px',
               minHeight: '35px',
            },
        },

         // headRow: {
         // 	style: {
         // 		backgroundColor: '#c9a0a7',
         // 		minHeight: '52px'
         // 	},
         // },
      };

      const handleGsPageChange = page => {
         fetchGraphicSymbols(location.id, '', 'id', 'asc',  gsPageLimit , page - 1);
         setGsCurrPage(page);
      };
      
      const handleGsPerRowsChange = async (newPerPage, page) => {
         fetchGraphicSymbols(location.id, '', 'id', 'asc',  newPerPage , page - 1);
         setGsPageLimit(newPerPage);
         setGsCurrPage(page);
      };

      const handleDocsPageChange = page => {
         fetchDocuments(location.id, '', 'id', 'asc',  docsPageLimit , page - 1);
         setDocsCurrPage(page);
      };
      
      const handleDocsPerRowsChange = async (newPerPage, page) => {
         fetchDocuments(location.id, '', 'id', 'asc',  newPerPage , page - 1);
         setDocsPageLimit(newPerPage);
         setDocsCurrPage(page);
      };

      const loadingAnimation = (
         <div className="m-5 p-5"><Loading /></div>
      )

      if (selectedTab==='gs' && !isLoading){
         grid = (
            <div>
               <div className="mb-3">
               Graphic symbols found: <span style={{color: "#822333", fontWeight: "bold"}}>
                  { gsTotalRows }</span>
               </div>
               <div style={{ width: '100%', margin: "0 0 0" }}>
                  <DataTable
                     striped
                     highlightOnHover
                     columns={gsColumns}
                     data={gsList}
                     pagination
                     paginationServer
                     paginationTotalRows={gsTotalRows}
                     paginationDefaultPage={gsCurrPage}
                     paginationPerPage={gsPageLimit}
                     paginationRowsPerPageOptions={[5,20,50]}
                     onChangeRowsPerPage={handleGsPerRowsChange}
                     onChangePage={handleGsPageChange}
                     customStyles={dataTableStyle}	
                  />
               </div>
            </div>
         )
      } else if (selectedTab==='docs' && !isLoading){
         grid = (
            <div>
               <div className="mb-3">
               Documents found: <span style={{color: "#822333", fontWeight: "bold"}}>
                  { docsTotalRows }</span>
               </div>
               <div style={{ width: '100%', margin: "0 0 0" }}>
                  <DataTable
                     striped
                     highlightOnHover
                     columns={docsColumns}
                     data={docsList}
                     pagination
                     paginationServer
                     paginationTotalRows={docsTotalRows}
                     paginationDefaultPage={docsCurrPage}
                     paginationPerPage={docsPageLimit}
                     paginationRowsPerPageOptions={[5,20,50]}
                     onChangeRowsPerPage={handleDocsPerRowsChange}
                     onChangePage={handleDocsPageChange}
                     customStyles={dataTableStyle}	
                  />
               </div>
            </div>
         )
      } else {
         grid = loadingAnimation
      }

   }


   const optionsFilterCentury = [
      { value: 'all', label: '---'},
      { value: '200-249', label: '200-249'},
      { value: '250-299', label: '250-299'},
      { value: '300-349', label: '300-349'},
      { value: '350-399', label: '350-399'},
      { value: '400-449', label: '400-449'},
      { value: '450-499', label: '450-499'},
      { value: '500-549', label: '500-549'},
      { value: '550-599', label: '550-599'},
      { value: '600-649', label: '600-649'},
      { value: '650-699', label: '650-699'},
      { value: '700-749', label: '700-749'},
      { value: '750-799', label: '750-799'}
   ]

   return (
      
      location !== undefined && 
      <section className="Sidebar">
         <Sidebar id="sidebar"
            map={map}
            position="right"
            collapsed={!openTab}
            selected={openTab}
            closeIcon={<FiChevronLeft />}
            onClose={onClose}
            onOpen={onOpen}
            panMapOnChange
            rehomeControls>

            <Tab id="gs" header={location.name} icon={<FontAwesomeIcon icon="earth" />} active>
               <Row>
                  <ButtonGroup color="secondary" aria-label="secondary button group" data-toggle="button" 
                     style={{height: '50px', width: '100%', marginTop: '10px', marginLeft: '10px', marginRight: '10px'}}> 
                     <Button className={selectedTab ==='gs' ? 'active' : null}
                        color="secondary"
                        outline
                        onClick={()=> { 
                           //console.log("gs")
                           setSelectedTab('gs');
                        }} >Graphic symbols ({gsTotalRows})
                     </Button> 
                     <Button className={selectedTab ==='docs' ? 'active' : null}
                        color="secondary"
                        outline
                        onClick={()=> { 
                           //console.log("docs")
                           setSelectedTab('docs');
                        }} >Documents ({docsTotalRows})
                     </Button>
                  </ButtonGroup> 
               </Row>
               <br/>
               <div>
                  <Row>
                     <Col sm="5" style={{ alignItems: "center", display: "flex" }}>
                        <h5>Filter by date range: </h5>
                     </Col>
                     <Col>
                        <Select isSearchable={false}
                           options={ optionsFilterCentury }
                           onChange={ _changeValSelect } 
                           isMulti={true}
                           closeMenuOnSelect={false}
                           components={animatedComponents}
                           menuPortalTarget={document.body} 
                           styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                                     multiValue: styles => ({ ...styles, backgroundColor: '#e6f0ff' }),
                                     multiValueRemove: styles => ({ ...styles, ':hover': { backgroundColor: 'e6f0ff', color: 'red' } })
                                    }}
                        />
                     </Col>
                  </Row>
                  
               </div>
               
               <hr />

               <div>{ !isLoading? grid : <div className="m-5 p-5"><Loading /></div>}</div>
            
            </Tab>
         </Sidebar>

      </section>
   )
}

export default SidebarComponent