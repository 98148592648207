import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import FieldSet from '../FieldSet';
import {estraiSiglaAutore} from '../Utils/utils'


export default class GSBody extends Component {

  constructor(props) {
    super();
    this.state = {
      valHand: props.valHand
    }
  }

  _changeValSelect = (v) =>{
    this.setState({
      valHand: v.constructor === Object && v.value ? v.value : v
    });
  }

  changeStateValHands = (v) =>{
    this.setState({
      valHand: v
    });
  }
  

  render() {
    var gs = this.props.gs;
    //console.log(gs)

    // set up "person.authors" considering also "other authors"
    var gsAuthors = "<p>" + estraiSiglaAutore(gs.userCreation) + "</p>";
    if (gs.otherAuthors !== "" && gs.otherAuthors !== null){
      var others = gs.otherAuthors.split("||^||").filter(Boolean);
      others.forEach(author => {
        gsAuthors = gsAuthors + "<p>" + estraiSiglaAutore(author) + "</p>"
      });
    }


    return (
      <div>
        <FieldSet legend="Main Informations" fields={[
          ["Shape", gs.shape],
          ["Category", gs.category],
          ["Elements", gs.elements],
          ["Description", gs.description],
          ["Position", gs.position]
        ]} />
        
        {//Hand
          //showHands && this.state.valHand !== undefined &&
          <fieldset className="p-3 border border-white mt-3">
            <legend 
              style={{ fontSize: '1.3rem', 'fontWeight': 'bold', color:'#822333'}}>Hand</legend>

            <Row className={"border-bottom mt-2 mb-2 pb-1"}>
              <Col sm="4" md="3">
                <div><label className="font-weight-bold">ID</label></div>
              </Col>
              <Col>
                <div><label id="handIdTxt" dangerouslySetInnerHTML={{__html: gs.handId}}></label></div>
              </Col>
            </Row>

            <Row  className={"border-bottom mt-2 mb-2 pb-1"}>
              <Col sm="4" md="3">
                <div><label className="font-weight-bold">Position</label></div>
              </Col>
              <Col>
                <div><label id="handPositionTxt" dangerouslySetInnerHTML={{__html: gs.handPosition}}></label></div>
              </Col>
            </Row>

            <Row  className={"border-bottom mt-2 mb-2 pb-1"}>
              <Col sm="4" md="3">
                <div><label className="font-weight-bold">Person</label></div>
              </Col>
              <Col>
                <a href={ "/search/person/"+gs.handPersonId } target="_blank" rel="noopener noreferrer">
                  Person {gs.handPersonId}
                </a>
              </Col>
            </Row>

            <Row  className={"border-bottom mt-2 mb-2 pb-1"}>
              <Col sm="4" md="3">
                <div><label className="font-weight-bold">Role</label></div>
              </Col>
              <Col>
                <div><label id="handRoleTxt" dangerouslySetInnerHTML={{__html: gs.handRole}}></label></div>
              </Col>
            </Row>

            <Row  className={"border-bottom mt-2 mb-2 pb-1"}>
              <Col sm="4" md="3">
                <div><label className="font-weight-bold">Script</label></div>
              </Col>
              <Col>
                <div><label id="handScriptTxt"  dangerouslySetInnerHTML={{__html: gs.handScript}}></label></div>
              </Col>
            </Row>
            <Row  className={"border-bottom mt-2 mb-2 pb-1"}>
              <Col sm="4" md="3">
                <div><label className="font-weight-bold">Comments</label></div>
              </Col>
              <Col>
                <div><label id="handCommentTxt" dangerouslySetInnerHTML={{__html: gs.handComment}}></label></div>
              </Col>
            </Row>
          </fieldset>
        }

        {//GS Comments
          <fieldset className="p-3 border border-white mt-3">
            <legend style={{ fontSize: '1.3rem', 'fontWeight': 'bold', color:'#822333'}}>Comments</legend>
            <Row className={"border-bottom mt-2 mb-2 pb-1"}>
              <Col>
                <div><label dangerouslySetInnerHTML={{ __html: gs.comment }}></label></div>
              </Col>
            </Row>
          </fieldset>
        }

        <FieldSet legend="Metadata" fields={[
          ["Author(s)", gsAuthors],
          // ["Status", gs.state],
          ["Last Modified", gs.monthLastUpdate.split('T')[0]]
        ]} />

      </div>
    );
  }
}