import React, { Component } from 'react';
import { Form, FormGroup, Button, Col, Row } from 'reactstrap';
import Select from 'react-select'
import PValueInput from './PValueInput';


export default class PSearchForm extends Component {

  constructor(props) {
    super();
    this.state = {
      personID: props.personID,
      personTrismegistos: props.personTrismegistos,
      personName: props.personName,
      personGender: props.personGender,
      personRoles: props.personRoles,
      personStatus: props.personStatus
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this._changePersonID = this._changePersonID.bind(this);
    this._changePersonTrismegistos = this._changePersonTrismegistos.bind(this);
    this._changePersonName = this._changePersonName.bind(this);
    this._changePersonStatus = this._changePersonStatus.bind(this);
  }

  componentDidUpdate(prevProps) { }

  resetForm = (event) => {
    event.preventDefault();
    
    this.setState({
      personID: '',
      personTrismegistos: '',
      personName: '',
      personGender: '',
      personRoles: '',
      personStatus: ''
    });

    /* soluzione provvisoria brutta... da capire come ripulire i campi della ricerca in un class component*/
    window.location.reload(false);
  }

  _changePersonID(v){
    if (v !== ''){
      this.setState({
        personID: 'id||^||' + (v.constructor === Array ? v[0] : v)
      });
    } else {
      this.setState({
        personID: undefined
      });
    }
  }

  _changePersonTrismegistos(v){
    if (v !== ''){
      this.setState({
        personTrismegistos: 'idTrismegistos||^||' + (v.constructor === Array ? v[0] : v)
      });
    } else {
      this.setState({
        personTrismegistos: undefined
      });
    }
  }

  _changePersonName(v){
    if (v !== ''){
      this.setState({
        personName: 'name||^||' + (v.constructor === Array ? v[0] : v)
      });
    } else {
      this.setState({
        personName: undefined
      });
    }
  }

  _changePersonGender = (v) =>{
    if (v.value !== ''){
      this.setState({
        personGender: 'gender||^||' + (v.constructor === Object && v.value ? v.value : v)
      });
    } else {
      this.setState({
        personGender: undefined
      });
    }
  }

  _changePersonRoles = (v) =>{
    if (v.value !== ''){
      this.setState({
        personRoles: 'roles||^||' + (v.constructor === Object && v.value ? v.value : v)
      });
    } else {
      this.setState({
        personRoles: undefined
      });
    }
  }

  _changePersonStatus(v){
    if (v !== ''){
      this.setState({
        personStatus: 'status||^||' + (v.constructor === Array ? v[0] : v)
      });
    } else {
      this.setState({
        personStatus: undefined
      });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    //console.log(this.state);

    if (!this.state.personID && !this.state.personTrismegistos && !this.state.personName 
      && !this.state.personGender && !this.state.personRoles && !this.state.personStatus ){
        alert('Insert a filter to continue');
        return;
    }
    
    var advancedFilter = ''
    /* check every field and fill the value for the advanced search*/
    if (this.state.personID !== undefined){
      advancedFilter = advancedFilter + this.state.personID + '##^##';
    }
    if (this.state.personTrismegistos !== undefined){
      advancedFilter = advancedFilter + this.state.personTrismegistos + '##^##';
    }
    if (this.state.personName !== undefined){
      advancedFilter = advancedFilter + this.state.personName + '##^##';
    }
    if (this.state.personGender !== undefined){
      advancedFilter = advancedFilter + this.state.personGender + '##^##';
    }
    if (this.state.personRoles !== undefined){
      advancedFilter = advancedFilter + this.state.personRoles + '##^##';
    }
    if (this.state.personStatus !== undefined){
      advancedFilter = advancedFilter + this.state.personStatus + '##^##';
    }

    //console.log("filter: " + advancedFilter)
    this.props.onSubmit(advancedFilter);
  }
  

  render() {

    const optionsGender = [
      { value: 'Male', label: 'Male' },
      { value: 'Female', label: 'Female' },
      { value: 'Zero', label: 'unknown' }
    ]

    const optionsRoles = [
      { value: 'scribe', label: 'Scribe' },
      { value: 'subscriber (witness)', label: 'Subscriber (witness)' }, 
      { value: 'subscriber (writes on behalf of the issuer)', label: 'Subscriber (writes on behalf of the issuer)' }, 
      { value: 'hypographeus', label: 'Hypographeus' },
      { value: 'subscriber (issuer)', label: 'Subscriber (issuer)' },
      { value: 'subscriber (writes the confirmation signature)', label: 'Subscriber (writes the confirmation signature)' },
      { value: 'responsible for the verso', label: 'Responsible for the verso' }
    ]
    

    return (
      <div>
        <Form onSubmit={this.handleSubmit} onReset={this.resetForm} className="form" id='searchForm'>
        
          <FormGroup row>
            <Col sm={3}>
              <p><b>ID :</b></p>
            </Col>
            <Col sm={5}>
              <PValueInput
                fld={ 'id' }
                personID={this.state.personID}
                onChange={this._changePersonID}/>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={3}>
              <p><b>TM Person :</b></p>
            </Col>
            <Col sm={5}>
              <PValueInput
                fld={ 'idTrismegistos' }
                personTrismegistos={this.state.personTrismegistos}
                onChange={this._changePersonTrismegistos}/>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={3}>
              <p><b>Name :</b></p>
            </Col>
            <Col sm={5}>
              <PValueInput
                fld={ 'name' }
                personName={this.state.personName}
                onChange={this._changePersonName}/>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={3}>
              <p><b>Gender :</b></p>
            </Col>
            <Col sm={5}>
              <Select isSearchable={false}
                options={ optionsGender }
                onChange={ this._changePersonGender }
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={3}>
              <p><b>Roles :</b></p>
            </Col>
            <Col sm={5}>
              <Select isSearchable={false}
                options={ optionsRoles }
                onChange={ this._changePersonRoles }
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              />
            </Col>     
          </FormGroup>

          <FormGroup row>
            <Col sm={3}>
                <p><b>Status :</b></p>
            </Col>
            <Col sm={5}>
              <PValueInput
                fld={ 'status' }
                personStatus={this.state.personStatus}
                onChange={this._changePersonStatus}/>
            </Col>
            <Col sm={2}>
              <Button type="reset" color="outline-primary" style={{ width:'120px', marginRight:'0' }}  >Clear fields</Button>
            </Col>
            <Col sm={2}>
              <Button type="submit" color="primary" block>Search</Button>
            </Col>
          </FormGroup>

          <Row>
            <p> <br></br></p>
          </Row>

        </Form>
      </div>
    );
  }
}
