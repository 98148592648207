import React, { Component } from 'react';
import qs from 'qs';
import SubHead from '../../../SubHead/SubHead';
import GSSearchForm from './GSSearchForm';
import GSSearchResults from './GSSearchResults';


export default class SearchGS extends Component {

  constructor(props) {
    super();

    const qstring = qs.parse(props.location.search, {ignoreQueryPrefix: true});
    this.state = {
      val: qstring.v
    };

    this._onFormSubmit = this._onFormSubmit.bind(this);
  }

  _onFormSubmit(val){
    //const s = `${encodeURIComponent(val)}`;
    this.setState({
      val: val
    });
    this.props.history.push(`/search/graphicsymbols`);
  }

  componentDidUpdate(prevProps, prevState) { }

  render() {
    return (
      <div>
        <SubHead tblabel={'Graphic Symbols'} tb={'graphicsymbols'} text='Search' />

      	<div className="mt-3 p-1 container">
            <GSSearchForm
              tb={ this.props.match.params.table }
              onSubmit={this._onFormSubmit}
              val={this.state.val}
            />
          <hr />
  		  </div>

        { this.state.val && <GSSearchResults
            tb={this.props.match.params.table}
            type="simple"
            val={this.state.val}
            />}
      </div>
    );
  }
}
