import axios from 'axios';

export const SET_DOCUMENTS = 'SET_DOCUMENTS';
export const SET_GRAPHIC_SYMBOLS = 'SET_GRAPHIC_SYMBOLS';
export const SET_PERSONS = 'SET_PERSONS';
export const SET_LOCATIONS = 'SET_LOCATIONS';

export function setDocuments(result) {
	return { type: SET_DOCUMENTS, result };
}

export function setGraphicSymbols(result) {
	return { type: SET_GRAPHIC_SYMBOLS, result };
}

export function setPersons(result) {
	return { type: SET_PERSONS, result };
}

export function setLocations(result) {
	return { type: SET_LOCATIONS, result };
}

//get locations for Map
export function fetchLocations(filter, sortField, sortDirection, pageSize, pageIndex) {
	return dispatch => {
		return axios.get('/api/locations/all', {params: {filter:filter, sortingField:sortField, sortingOrder:sortDirection, limit:pageSize, offset:pageIndex*pageSize}})
		.then(function (response) {
			dispatch(setLocations(response.data.result))
			//console.log(response.data)
		})
		.catch(function (error) {
			console.log(error);
		});
	}
}

// ricerca Documents "Show All" and "Search"
export function fetchDocuments(filter = '', sortField = 'id', sortDirection= 'asc', pageSize = 20 , pageIndex = 0) {
	return dispatch => {
		return axios.get('/api/documents/all', {params: {filter:filter, sortingField:sortField, sortingOrder:sortDirection, limit:pageSize, offset:pageIndex*pageSize}})
		.then(function (response) {
			dispatch(setDocuments(response.data.result))
			//console.log(response.data.result)
		})
		.catch(function (error) {
			console.log(error);
		});
	}
}

// get Document by id for "Document View"
export function fetchDocByID(id) {
	return dispatch => {
		return axios.get(`/api/documents/${id}`, {params: {docid:id}})
		.then(function (response) {
			dispatch(setDocuments(response.data.result))
			//console.log(response.data.result)
		})
		.catch(function (error) {
			console.log(error);
		});
	}
}

export function fetchGraphicSymbols(filter = '', sortField = 'id', sortDirection= 'asc', pageSize = 20 , pageIndex = 0) {
	return dispatch => {
		return axios.get('/api/graphicsymbols/all', {params: {filter:filter, sortingField:sortField, sortingOrder:sortDirection, limit:pageSize, offset:pageIndex*pageSize}})
		.then(function (response) {
			dispatch(setGraphicSymbols(response.data.result))
			//console.log(response.data.result)
		})
		.catch(function (error) {
			console.log(error);
		});
	}
}

// get GS by id for "GS View"
export function fetchGSByID(id) {
	return dispatch => {
		return axios.get(`/api/graphicsymbols/${id}`, {params: {gsId:id}})
		.then(function (response) {
			dispatch(setGraphicSymbols(response.data.result))
			//console.log(response.data.result)
		})
		.catch(function (error) {
			console.log(error);
		});
	}
}

export function fetchPersons(filter = '', sortField = 'id', sortDirection= 'asc', pageSize = 20 , pageIndex = 0) {
	return dispatch => {
		return axios.get('/api/persons/all', {params: {filter:filter, sortingField:sortField, sortingOrder:sortDirection, limit:pageSize, offset:pageIndex*pageSize}})
		.then(function (response) {
			dispatch(setPersons(response.data.result))
			//console.log(response.data.result)
		})
		.catch(function (error) {
			console.log(error);
		});
	}
}

// get Person by id for "Person View"
export function fetchPersonByID(id) {
	return dispatch => {
		return axios.get(`/api/persons/${id}`, {params: {pid:id}})
		.then(function (response) {
			dispatch(setPersons(response.data.result))
			//console.log(response.data.result)
		})
		.catch(function (error) {
			console.log(error);
		});
	}
}