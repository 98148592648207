import React, { Component } from 'react';
import { Map, TileLayer } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import Markers from './VenueMarkers';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { fetchLocations } from '../Search/actions';
import Sidebar from './Sidebar';
import Loading from '../Loading/Loading';

class MapView extends Component {

  constructor(props) {
    super(props);
    this.state = {
      currentLocation: { lat: 41.882476, lng: 16.176749 },
      zoom: 0,
      location: undefined
    }
  }

  componentDidMount() {
		this.props.fetchLocations('', 'id', 'asc',  9000000000 , 0);
	}

  render() {
    const { currentLocation, zoom, location } = this.state;
    //console.log(this.props.locations.result.locations)
    var locations = this.props.locations.result.locations

    var map = 
      <div>
        <Sidebar map={this} location={location}/>
        <Map id="map" center={currentLocation} zoom={zoom}>
          <TileLayer
            minZoom= '4'
            maxZoom= '10'
            url='https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}.png'
            attribution='Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community'
            />
          <Markers venues={locations} map={this}/>
        </Map>
      </div> 

    return (
      locations !== undefined ? map : <div className="m-5 p-5"><Loading /></div>
    );
  }
}

MapView.propTypes = {
	locations: PropTypes.object.isRequired, 
	fetchLocations: PropTypes.func.isRequired
}

function mapStateToProps(state) {
	//console.log(state)
	return {
		locations: state
	}
}

export default connect(mapStateToProps, { fetchLocations })(MapView);