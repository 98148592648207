import React, {useState, useCallback} from 'react';
import { AsyncTypeahead } from 'react-bootstrap-typeahead';
import { fetchPersons } from '../../actions';


export default function ValueInput (props) {

  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState([]);

  const handleSearch = useCallback((string) => {
    setIsLoading(true);
    // SUGGERIMENTI DI RICERCA
    // fetchPersons(this.props.fld+'||^||'+string, 'id', 'asc',  20 , 0)
    //   .then((resp) => resp.json())
    //   .then(({ items }) => {
    //     setOptions(items);
    //     setIsLoading(false);
    //   });
    setIsLoading(false)
  }, [props.fld]);

  const defSelected = props.val && props.val.customOption ? props.val.customOption.label :
    ( props.val ? [props.val] : [] );

  return (
    //test commit e push

    <AsyncTypeahead
      id="get-value-from-db"
      isLoading={ isLoading }
      minLength={1}
      options={ options }
      multiple={ false }
      placeholder="Add a value"
      onSearch={handleSearch}
      onChange={props.onChange}
      onInputChange={props.onChange}
      defaultSelected={ defSelected }
      open={false}
      />
  );
}
