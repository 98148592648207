import React, { Component } from 'react';
import qs from 'qs';
import SubHead from '../../../SubHead/SubHead';
import DocsSearchForm from './DocsSearchForm';
import DocsSearchResults from './DocsSearchResults';


export default class SearchDocs extends Component {

  constructor(props) {
    super();

    const qstring = qs.parse(props.location.search, {ignoreQueryPrefix: true});
    this.state = {
      val: qstring.v
    };

    this._onFormSubmit = this._onFormSubmit.bind(this);
  }

  _onFormSubmit(val){
    //const s = `${encodeURIComponent(val)}`;
    this.setState({
      val: val
    });
    this.props.history.push(`/search/documents`);
  }

  componentDidUpdate(prevProps, prevState) { }

  render() {
    return (
      <div>
        <SubHead tblabel={'Documents'} tb={'documents'} text='Search' />

      	<div className="mt-3 p-1 container">
          <DocsSearchForm
              tb={ this.props.match.params.table }
              onSubmit={this._onFormSubmit}
              val={this.state.val}
             />
          <hr />
  		  </div>

        { this.state.val && <DocsSearchResults
            tb={this.props.match.params.table}
            type="simple"
            val={this.state.val}
            />}
      </div>
    );
  }
}
