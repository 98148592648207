import { SET_DOCUMENTS, SET_GRAPHIC_SYMBOLS, SET_PERSONS, SET_LOCATIONS } from '../components/Search/actions';


export default function info(state = [], action = {}) {
	switch (action.type) {
		case SET_DOCUMENTS:
			return action.result;
		case SET_GRAPHIC_SYMBOLS:
			return action.result;
		case SET_PERSONS:
			return action.result;
		case SET_LOCATIONS:
			return action.result
		default:
			return state; // bypass
	}
}
