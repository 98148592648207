import React, { Component } from 'react';
import { Card, CardBody, Row, Col, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import SubHead from '../../../SubHead/SubHead';
import Loading from '../../../Loading/Loading';
import { fetchDocByID } from '../../actions';
import DocumentBody from './DocumentBody';


class ViewDocDetail extends Component {
	constructor(props) {
		super(props);
		this.state = {
		  rec: false,
		  copied: false
		};
	}

	async componentDidMount() {
		//console.log(this.props.match.params)
		await this.props.fetchDocByID(this.props.match.params.id)
		if (this.state.rec !== true){
			this.setState({
				rec: true
			});
		}
	}

	renderTemplate(document) {
		//console.log("DOC: ", document)
		//console.log("GSS: ", this.props.document.result.gssIds)
		var gssIds = this.props.document.result.gssIds
		document.gssIds = gssIds

		return(
			<div>
				<SubHead tblabel={'Documents'} tb={'documents'} text="View Detail"  />
				<div className="mr-4 ml-4">
					<Row className="mt-2">
						<Col sm="8" >
							<Card className="card-customized">
								<CardBody>
									<DocumentBody doc={ document } />
								</CardBody>
							</Card>
						</Col>
						
						<Col sm="4">
							<h2 style={{fontWeight: 'bold', color: '#6b1e29'}}>
								NOTAE Doc { document.id }
							</h2>
							<div className="text-secondary d-flex justify-content-between ml-auto" >
								<div>
									<CopyToClipboard 
										text={ 'https://www.notae-erc.eu/search/document/' + document.id }
										onCopy={() => { this.setState({copied: true}); window.setTimeout(()=>{ this.setState({copied: false}); }, 2000); } }>
										<Button size="sm" color={ this.state.copied ? 'primary': 'secondary'}>
											<FontAwesomeIcon icon="clone" /> <span className="d-none d-sm-inline">
											{this.state.copied ? 'Link copied' : 'https://www.notae-erc.eu/search/document/' + document.id }
											</span>
										</Button>
									</CopyToClipboard>
								</div>
							</div>

							<br/><br/>

							<h4 style={{color: '#6b1e29'}}>
								Links
							</h4>
							
							<div>
							{ 	//Trismegistos link(s)
								document.trismegistosUrl !== null &&
								document.trismegistosUrl.split('||^||').filter(Boolean).map( (v, k) => {
								//console.log(v)
								return 	<div><a key={k} href={ v.trim() } target="_blank" rel="noopener noreferrer">
											<FontAwesomeIcon icon="external-link-alt" /> TM number {v.split('/').pop()}
										</a></div>
							}) }
							</div>

							<div>
							{ 	//Digital reproduction(s) link(s)
								document.digitalReproductions !== null &&
								document.digitalReproductions.map((dr,k) => {
									//console.log(dr)
									return 	<div><a key={k} href={ dr.url.replace('||^||', '').trim() } target="_blank" rel="noopener noreferrer">
												<FontAwesomeIcon icon="external-link-alt" /> Digital reproduction {dr.id}
											</a></div>
								})
							} 
							</div>
							
						</Col>
					</Row>
				</div>
			</div>
		)
	}
	  
	
	render() {
		if (!this.state.rec) {
			return <div className="m-5 p-5"><Loading /></div>;
		}

		return (
			<div className="mb-5">
				{ this.renderTemplate(this.props.document.result.document) }
			</div>
		);
	}
}

ViewDocDetail.propTypes = {
	document: PropTypes.object.isRequired, 
	fetchDocByID: PropTypes.func.isRequired
}

function mapStateToProps(state) {
	//console.log("STATE: ", state)
	return {
		document: state,
	}
}

export default connect(mapStateToProps, { fetchDocByID })(ViewDocDetail);