import React, { Component } from 'react';
import { Button } from 'reactstrap';
import PreviewModal from './PreviewModal';


export default class PreviewModalHidden extends Component {

  constructor(props) {
    super(props);
    this.state = {
      shown: false,
      buttonText: 'Show enhanced version'
    };
    this.toggle = this.toggle.bind(this);
  }

  toggle() {
    this.setState({
      shown: !this.state.shown,
      buttonText : this.state.buttonText === 'Show enhanced version' ? 'Close' : 'Show enhanced version'
    });
  }

  render(){
    return (
      <div>
        <br/>
        <div>
          <Button color="primary" style={{width: '60%', height: '50px'}} onClick={this.toggle}>{ this.state.buttonText}</Button>
        </div>
        <div style={{ display: this.state.shown ? 'block' : 'none' }} className="mt-3 image-preview">
          <PreviewModal file={this.props.file} description={this.props.description} />
        </div>
      </div>
    );
  }
}