import React, { Component } from 'react';
import { FormGroup, Button, Col, Row } from 'reactstrap';
import Select from 'react-select'
import DocsValueInput from './DocsValueInput';
import makeAnimated from 'react-select/animated';


export default class DocsSearchForm extends Component {

  constructor(props) {
    super();
    this.state = {
      docID: props.docID,
      docMainIdentifier: props.docMainIdentifier,
      docTrismegistos: props.docTrismegistos,
      docDateRange: props.docDateRange,
      docPlace: props.docPlace,
      docType: props.docType,
      docLanguage: props.docLanguage,
      docMaterial: props.docMaterial,
      docSymbols: props.docSymbols
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this._changeDocID = this._changeDocID.bind(this);
    this._changeDocMainIdentifier = this._changeDocMainIdentifier.bind(this);
    this._changeDocTrismegistos = this._changeDocTrismegistos.bind(this);
    this._changeDocPlace = this._changeDocPlace.bind(this);
    this._changeDocType = this._changeDocType.bind(this);
  }

  componentDidUpdate(prevProps) { }

  resetForm = (event) => {
    event.preventDefault();
    
    this.setState({
      docID: '',
      docMainIdentifier: '',
      docTrismegistos: '',
      docDateRange: '',
      docPlace: '',
      docType: '',
      docLanguage: '',
      docMaterial: '',
      docSymbols: ''
    });

    /* soluzione provvisoria brutta... da capire come ripulire i campi della ricerca in un class component*/
    window.location.reload(false);
  }

  _changeDocID(v){
    if (v !== ''){
      this.setState({
        docID: 'id||^||' + (v.constructor === Array ? v[0] : v)
      });
    } else {
      this.setState({
        docID: undefined
      });
    }
  }

  _changeDocMainIdentifier(v){
    if (v !== ''){
      this.setState({
        docMainIdentifier: 'identifier||^||' + (v.constructor === Array ? v[0] : v)
      });
    } else {
      this.setState({
        docMainIdentifier: undefined
      });
    }
  }

  _changeDocTrismegistos(v){
    if (v !== ''){
      this.setState({
        docTrismegistos: 'trismegistosUrl||^||' + (v.constructor === Array ? v[0] : v)
      });
    } else {
      this.setState({
        docTrismegistos: undefined
      });
    }
  }

  _changeDocDateRange = (v) =>{
    if (v.length > 0){
      var tempValue = 'century||^||'
      v.forEach(date => {
        tempValue = tempValue + date.value + ';'
      });
      this.setState({
        docDateRange: tempValue
      });
    } else {
      this.setState({
        docDateRange: undefined
      });
    }
  }

  _changeDocPlace(v){
    if (v !==''){
      this.setState({
        docPlace: 'place||^||' + (v.constructor === Array ? v[0] : v)
      });
    } else {
      this.setState({
        docPlace: undefined
      });
    }
  }

  _changeDocType(v){
    if (v !==''){
      this.setState({
        docType: 'docType||^||' + (v.constructor === Object && v.value ? v.value : v)
      });
    } else {
      this.setState({
        docType: undefined
      });
    }
  }

  _changeDocLanguage = (v) =>{
    if (v.value !==''){
      this.setState({
        docLanguage: 'language||^||' + (v.constructor === Object && v.value ? v.value : v)
      });
    } else {
      this.setState({
        docLanguage: undefined
      });
    }
  }

  _changeDocMaterial = (v) =>{
    if (v.value !==''){
      this.setState({
        docMaterial: 'material||^||' + (v.constructor === Object && v.value ? v.value : v)
      });
    } else {
      this.setState({
        docMaterial: undefined
      });
    }
  }

  _changeDocSymbols = (v) =>{
    if (v.value !==''){
      this.setState({
        docSymbols: 'maybeSymbols||^||' + (v.constructor === Object && v.value ? v.value : v) // TODO rivedi field 'docType' nel backend
      });
    } else {
      this.setState({
        docSymbols: undefined
      });
    }
  }


  handleSubmit(event) {
    event.preventDefault();
    //console.log(this.state);

    if (!this.state.docID && !this.state.docMainIdentifier && !this.state.docTrismegistos && !this.state.docDateRange
      && !this.state.docPlace && !this.state.docType && !this.state.docLanguage && !this.state.docMaterial && !this.state.docSymbols){
        alert('Insert a filter to continue');
        return;
    }
    
    var advancedFilter = ''
    /* check every field and fill the value for the advanced search*/
    if (this.state.docID !== undefined){
      advancedFilter = advancedFilter + this.state.docID + '##^##';
    }
    if (this.state.docMainIdentifier !== undefined){
      advancedFilter = advancedFilter + this.state.docMainIdentifier + '##^##';
    }
    if (this.state.docTrismegistos !== undefined){
      advancedFilter = advancedFilter + this.state.docTrismegistos + '##^##';
    }
    if (this.state.docDateRange !== undefined){
      advancedFilter = advancedFilter + this.state.docDateRange + '##^##';
    }
    if (this.state.docPlace !== undefined){
      advancedFilter = advancedFilter + this.state.docPlace + '##^##';
    }
    if (this.state.docType !== undefined){
      advancedFilter = advancedFilter + this.state.docType + '##^##';
    }
    if (this.state.docLanguage !== undefined){
      advancedFilter = advancedFilter + this.state.docLanguage + '##^##';
    }
    if (this.state.docMaterial !== undefined){
      advancedFilter = advancedFilter + this.state.docMaterial + '##^##';
    }
    if (this.state.docSymbols !== undefined){
      advancedFilter = advancedFilter + this.state.docSymbols + '##^##';
    }
    //console.log("filter: " + advancedFilter)
    this.props.onSubmit(advancedFilter);
  }
  

  render() {

    // manage animations in select (multi options) component
    const animatedComponents = makeAnimated();

    const optionsLanguage = [
      { value: 'greek', label: 'Greek' },
      { value: 'coptic', label: 'Coptic' },
      { value: 'latin', label: 'Latin' }
    ]

    const optionsDocType = [
      { value: 'Documents (issued) by rulers', label: 'Documents (issued) by rulers' },
      { value: 'Documents and letters (issued) by officials, bishops, abbots' , label: 'Documents and letters (issued) by officials, bishops, abbots' },
      { value: 'Official proceedings and courts proceedings' , label: 'Official proceedings and courts proceedings' },
      { value: 'Written records related to the state administration (fiscal, military et sim.)' , label: 'Written records related to the state administration (fiscal, military et sim.)' },
      { value: 'Petitions, complaints, letters, declarations to rulers, officials, bishops, abbots', label: 'Petitions, complaints, letters, declarations to rulers, officials, bishops, abbots' },
      { value: 'Written records related to disputes, dispute settlements, compromises, arbitrations, private agreements' , label: 'Written records related to disputes, dispute settlements, compromises, arbitrations, private agreements' },
      { value: 'Documents recording negotia and contracts' , label: 'Documents recording negotia and contracts' },
      { value: 'Documents recording wills, testaments, bequests' , label: 'Documents recording wills, testaments, bequests' },
      { value: 'Written records related to private business sphere, trade activity, estate management' , label: 'Written records related to private business sphere, trade activity, estate management' },
      { value: 'Lists, inventories, registers' , label: 'Lists, inventories, registers' },
      { value: 'Labels' , label: 'Labels' },
      { value: 'Private letters' , label: 'Private letters' },
      { value: 'Writing exercises / school exercises' , label: 'Writing exercises / school exercises' },
      { value: 'Written records related to religious and magical sphere' , label: 'Written records related to religious and magical sphere' },
      { value: 'Fragments and written records of uncertain nature or content', label: 'Fragments and written records of uncertain nature or content' }
    ]

    const optionsCentury = [
      { value: '200-249', label: '200-249'},
      { value: '250-299', label: '250-299'},
      { value: '300-349', label: '300-349'},
      { value: '350-399', label: '350-399'},
      { value: '400-449', label: '400-449'},
      { value: '450-499', label: '450-499'},
      { value: '500-549', label: '500-549'},
      { value: '550-599', label: '550-599'},
      { value: '600-649', label: '600-649'},
      { value: '650-699', label: '650-699'},
      { value: '700-749', label: '700-749'},
      { value: '750-799', label: '750-799'}
    ]

    const optionsMaybeSymbols = [
      { value: 'yes', label: 'Yes' },
      { value: 'no', label: 'No' },
      { value: 'maybe', label: 'Maybe' }
    ]

    const optionsMaterial = [
      { value: 'Papyrus', label: 'Papyrus' },
      { value: 'Leather', label: 'Leather' },
      { value: 'Slate', label: 'Slate' },
      { value: 'Parchment', label: 'Parchment' },
      { value: 'Cedar-wood', label: 'Cedar-wood' }
    ]


    return (
      <div>
        <form onSubmit={this.handleSubmit} onReset={this.resetForm} className="form">

          <FormGroup row>
            <Col sm={3}>
                <p><b>ID :</b></p>
            </Col>
            <Col sm={5}>
                <DocsValueInput
                  fld={ 'id' }
                  docId={this.state.docID}
                  onChange={this._changeDocID}/>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={3}>
                <p><b>Main Identifier :</b></p>
            </Col>
            <Col sm={5}>
                <DocsValueInput
                  fld={ 'identifier' }
                  docMainIdentifier={this.state.docMainIdentifier}
                  onChange={this._changeDocMainIdentifier}/>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={3}>
                <p><b>TM number :</b></p>
            </Col>
            <Col sm={5}>
              <DocsValueInput
                fld={ 'trismegistosUrl' }
                docTrismegistos={this.state.docTrismegistos}
                onChange={this._changeDocTrismegistos}/>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={3}>
                <p><b>Date Range :</b></p>
            </Col>
            <Col sm={5}>
              <Select isSearchable={false}
                isMulti={true}
                closeMenuOnSelect={false}
                components={animatedComponents}
                options={ optionsCentury }
                onChange={ this._changeDocDateRange }
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                          multiValue: styles => ({ ...styles, backgroundColor: '#e6f0ff' }),
                          multiValueRemove: styles => ({ ...styles, ':hover': { backgroundColor: 'e6f0ff', color: 'red' } })
                }}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={3}>
                <p><b>Place :</b></p>
            </Col>
            <Col sm={5}>
              <DocsValueInput
                fld={ 'place' }
                docPlace={this.state.docPlace}
                onChange={this._changeDocPlace}/>
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={3}>
                <p><b>Type of Document :</b></p>
            </Col>
            <Col sm={5}>
              <Select isSearchable={false}
                options={ optionsDocType }
                onChange={ this._changeDocType }
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={3}>
                <p><b>Language :</b></p>
            </Col>
            <Col sm={5}>
              <Select isSearchable={false}
                options={ optionsLanguage }
                onChange={ this._changeDocLanguage }
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={3}>
                <p><b>Material :</b></p>
            </Col>
            <Col sm={5}>
              <Select isSearchable={false}
                options={ optionsMaterial }
                onChange={ this._changeDocMaterial }
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={3}>
                <p><b>Presence of Symbols :</b></p>
            </Col>
            <Col sm={5}>
              <Select isSearchable={false}
                options={ optionsMaybeSymbols }
                onChange={ this._changeDocSymbols }
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              />
            </Col>
            <Col sm={2}>
              <Button type="reset" color="outline-primary" style={{ width:'120px', marginRight:'0' }}>Clear fields</Button>
            </Col>
            <Col sm={2}>
              <Button type="submit" color="primary" block>Search</Button>
            </Col>
          </FormGroup>

          <Row>
            <p>  <br></br></p>
          </Row>
        </form>
      </div>
    );
  }
}