import React, { Component } from 'react';
import { FormGroup, Button, Col, Row } from 'reactstrap';
import Select from 'react-select'
import GSValueInput from './GSValueInput';
import makeAnimated from 'react-select/animated';


export default class GSSearchForm extends Component {

  constructor(props) {
    super();

    this.state = {
      gsID: props.gsID,
      gsMainIdentifier: props.gsMainIdentifier,
      gsShape: props.gsShape,
      gsCategory: props.gsCategory,
      gsElements: props.gsElements,
      gsDescription: props.gsDescription,
      gsDateRange: props.gsDateRange,
      gsPlace: props.gsPlace,
      gsTypeDoc: props.gsTypeDoc
    }

    this.handleSubmit = this.handleSubmit.bind(this);
    this.resetForm = this.resetForm.bind(this);
    this._changeGsID = this._changeGsID.bind(this);
    this._changeGsMainIdentifier = this._changeGsMainIdentifier.bind(this);
    this._changeGsDescription = this._changeGsDescription.bind(this);
    this._changeGsPlace = this._changeGsPlace.bind(this);
  }

  componentDidUpdate(prevProps) {}

  resetForm = (event) => {
    event.preventDefault();
    
    this.setState({
      gsID:'',
      gsMainIdentifier: '',
      gsShape: '',
      gsCategory: '',
      gsElements: '',
      gsDescription: '',
      gsDateRange: '',
      gsPlace: '',
      gsTypeDoc: ''
    });

    /* soluzione provvisoria brutta... da capire come ripulire i campi della ricerca in un class component*/
    window.location.reload(false);
  }

  _changeGsID(v){
    if (v !== ''){
      this.setState({
        gsID: 'id||^||' + (v.constructor === Array ? v[0] : v)
      });
    } else {
      this.setState({
        gsID: undefined
      });
    }
  }

  _changeGsMainIdentifier(v){
    if (v !== ''){
      this.setState({
        gsMainIdentifier: 'mainIdentifier||^||' + (v.constructor === Array ? v[0] : v)
      });
    } else {
      this.setState({
        gsMainIdentifier: undefined
      });
    }
  }

  _changeGsShape = (v) =>{
    if (v.value !== ''){
      this.setState({
        gsShape: 'shape||^||' + (v.constructor === Object && v.value ? v.value : v)
      });
    } else {
      this.setState({
        gsShape: undefined
      });
    }
  }

  _changeGsCategory = (v) =>{
    if (v.value !== ''){
      this.setState({
        gsCategory: 'category||^||' + (v.constructor === Object && v.value ? v.value : v)
      });
    } else {
      this.setState({
        gsCategory: undefined
      });
    }
  }

  _changeGsElements = (v) =>{
    if (v.value !== ''){
      this.setState({
        gsElements: 'elements||^||' + (v.constructor === Object && v.value ? v.value : v)
      });
    } else {
      this.setState({
        gsElements: undefined
      });
    }
  }

  _changeGsDescription(v){
    if (v !== ''){
      this.setState({
        gsDescription: 'description||^||' + (v.constructor === Array ? v[0] : v)
      });
    } else {
      this.setState({
        gsDescription: undefined
      });
    }
  }

  _changeGsDateRange = (v) =>{
    if (v.length > 0){
      var tempValue = 'century||^||'
      v.forEach(date => {
        tempValue = tempValue + date.value + ';'
      });
      this.setState({
        gsDateRange: tempValue
      });
    } else {
      this.setState({
        gsDateRange: undefined
      });
    }
  }

  _changeGsPlace(v){
    if (v !== ''){
      this.setState({
        gsPlace: 'place||^||' + (v.constructor === Array ? v[0] : v)
      });
    } else {
      this.setState({
        gsPlace: undefined
      });
    }
  }

  _changeGsTypeDoc = (v) =>{
    if (v.value !== ''){
      this.setState({
        gsTypeDoc: 'docType||^||' + (v.constructor === Object && v.value ? v.value : v)
      });
    } else {
      this.setState({
        gsTypeDoc: undefined
      });
    }
  }
  

  handleSubmit(event){
    event.preventDefault();
    //console.log(this.state);

    if (!this.state.gsID && !this.state.gsMainIdentifier && !this.state.gsShape && !this.state.gsCategory
      && !this.state.gsElements && !this.state.gsDescription && !this.state.gsDateRange && !this.state.gsPlace && !this.state.gsTypeDoc){
        alert('Insert a filter to continue');
        return;
    }
    
    var advancedFilter = ''
    /* check every field and fill the value for the advanced search*/
    if (this.state.gsID !== undefined){
      advancedFilter = advancedFilter + this.state.gsID + '##^##';
    }
    if (this.state.gsMainIdentifier !== undefined){
      advancedFilter = advancedFilter + this.state.gsMainIdentifier + '##^##';
    }
    if (this.state.gsShape !== undefined){
      advancedFilter = advancedFilter + this.state.gsShape + '##^##';
    }
    if (this.state.gsCategory !== undefined){
      advancedFilter = advancedFilter + this.state.gsCategory + '##^##';
    }
    if (this.state.gsElements !== undefined){
      advancedFilter = advancedFilter + this.state.gsElements + '##^##';
    }
    if (this.state.gsDescription !== undefined){
      advancedFilter = advancedFilter + this.state.gsDescription + '##^##';
    }
    if (this.state.gsDateRange !== undefined){
      advancedFilter = advancedFilter + this.state.gsDateRange + '##^##';
    }
    if (this.state.gsPlace !== undefined){
      advancedFilter = advancedFilter + this.state.gsPlace + '##^##';
    }
    if (this.state.gsTypeDoc !== undefined){
      advancedFilter = advancedFilter + this.state.gsTypeDoc + '##^##';
    }
    
    //console.log("filter: " + advancedFilter)
    this.props.onSubmit(advancedFilter);
  }
  

  render() {

    // manage animations in select (multi options) component
    const animatedComponents = makeAnimated();

    const optionsShape = [
      { value: 'complex', label: 'Complex' },
      { value: 'simple', label: 'Simple' }
    ]
    const optionsCategory = [
      { value: 'circle-shaped', label: 'Circle-Shaped' },
      { value: 'cross-shaped', label: 'Cross-Shaped' },
      { value: 'square-shaped', label: 'Square-Shaped' }
    ]
    const optionsElements = [
      { value: 'Strokes;', label: 'Strokes' },
      { value: 'Alphabetical Signs;', label: 'Alphabetical Signs' },
      { value: 'Alphabetical Signs; Strokes;', label: 'Alphabetical Signs, Strokes' },
      { value: 'Alphabetical Signs; Tachygraphic / Tironian notes;', label: 'Alphabetical Signs, Tachygraphic / Tironian notes' },
      { value: 'Alphabetical Signs; Tachygraphic / Tironian notes; Strokes;', label: 'Alphabetical Signs, Tachygraphic / Tironian notes, Strokes' },
      { value: 'Tachygraphic / Tironian notes;', label: 'Tachygraphic / Tironian notes' },
      { value: 'Tachygraphic / Tironian notes; Strokes;', label: 'Tachygraphic / Tironian notes, Strokes' }
    ]

    const optionsDocType = [
      { value: 'Documents (issued) by rulers', label: 'Documents (issued) by rulers' },
      { value: 'Documents and letters (issued) by officials, bishops, abbots' , label: 'Documents and letters (issued) by officials, bishops, abbots' },
      { value: 'Official proceedings and courts proceedings' , label: 'Official proceedings and courts proceedings' },
      { value: 'Written records related to the state administration (fiscal, military et sim.)' , label: 'Written records related to the state administration (fiscal, military et sim.)' },
      { value: 'Petitions, complaints, letters, declarations to rulers, officials, bishops, abbots', label: 'Petitions, complaints, letters, declarations to rulers, officials, bishops, abbots' },
      { value: 'Written records related to disputes, dispute settlements, compromises, arbitrations, private agreements' , label: 'Written records related to disputes, dispute settlements, compromises, arbitrations, private agreements' },
      { value: 'Documents recording negotia and contracts' , label: 'Documents recording negotia and contracts' },
      { value: 'Documents recording wills, testaments, bequests' , label: 'Documents recording wills, testaments, bequests' },
      { value: 'Written records related to private business sphere, trade activity, estate management' , label: 'Written records related to private business sphere, trade activity, estate management' },
      { value: 'Lists, inventories, registers' , label: 'Lists, inventories, registers' },
      { value: 'Labels' , label: 'Labels' },
      { value: 'Private letters' , label: 'Private letters' },
      { value: 'Writing exercises / school exercises' , label: 'Writing exercises / school exercises' },
      { value: 'Written records related to religious and magical sphere' , label: 'Written records related to religious and magical sphere' },
      { value: 'Fragments and written records of uncertain nature or content', label: 'Fragments and written records of uncertain nature or content' }
    ]

    const optionsCentury = [
      { value: '200-249', label: '200-249'},
      { value: '250-299', label: '250-299'},
      { value: '300-349', label: '300-349'},
      { value: '350-399', label: '350-399'},
      { value: '400-449', label: '400-449'},
      { value: '450-499', label: '450-499'},
      { value: '500-549', label: '500-549'},
      { value: '550-599', label: '550-599'},
      { value: '600-649', label: '600-649'},
      { value: '650-699', label: '650-699'},
      { value: '700-749', label: '700-749'},
      { value: '750-799', label: '750-799'}
    ]


    return (
      <div>
        <form onSubmit={this.handleSubmit} onReset={this.resetForm} className="form">

          <FormGroup row>
            <Col sm={3}>
                <p><b>ID :</b></p>
            </Col>
            <Col sm={5}>
                <GSValueInput
                  fld={ 'id' }
                  gsId={this.state.gsID}
                  onChange={this._changeGsID}
                  />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={3}>
                <p><b>Main Identifier :</b></p>
            </Col>
            <Col sm={5}>
                <GSValueInput ref={this.mainIdentifier}
                  fld={ 'mainIdentifier' }
                  gsId={this.state.gsMainIdentifier}
                  onChange={this._changeGsMainIdentifier}
                  />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={3}>
              <p><b>Shape :</b></p>
            </Col>
            <Col sm={5}>
              <Select isSearchable={false} ref={this.shape}
                options={ optionsShape }
                onChange={this._changeGsShape}
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
              />
            </Col>           
          </FormGroup>

          <FormGroup row>
            <Col sm={3}>
                <p><b>Category :</b></p>
            </Col>
            <Col sm={5}>
                  <Select isSearchable={false}
                    options={ optionsCategory }
                    onChange={ this._changeGsCategory }
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={3}>
                <p><b>Elements :</b></p>
            </Col>
            <Col sm={5}>
                  <Select isSearchable={false}
                    options={ optionsElements }
                    onChange={ this._changeGsElements }
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={3}>
                <p><b>Description :</b></p>
            </Col>
            <Col sm={5}>
              <GSValueInput
                fld={ 'description' }
                gsDescription={this.state.gsDescription}
                onChange={this._changeGsDescription}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={3}>
                <p><b>Date Range :</b></p>
            </Col>
            <Col sm={5}>
              <Select isSearchable={false}
                options={ optionsCentury }
                onChange={ this._changeGsDateRange }
                isMulti={true}
                closeMenuOnSelect={false}
                components={animatedComponents}
                menuPortalTarget={document.body} 
                styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }),
                          multiValue: styles => ({ ...styles, backgroundColor: '#e6f0ff' }),
                          multiValueRemove: styles => ({ ...styles, ':hover': { backgroundColor: 'e6f0ff', color: 'red' } })
                }}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={3}>
              <p><b>Place :</b></p>
            </Col>
            <Col sm={5}>
              <GSValueInput
                fld={ 'place' }
                gsPlace={this.state.gsPlace}
                onChange={this._changeGsPlace}
              />
            </Col>
          </FormGroup>

          <FormGroup row>
            <Col sm={3}>
                <p><b>Type of Document :</b></p>
            </Col>
            <Col sm={5}>
                  <Select isSearchable={false}
                    options={ optionsDocType }
                    onChange={ this._changeGsTypeDoc }
                    menuPortalTarget={document.body} 
                    styles={{ menuPortal: base => ({ ...base, zIndex: 9999 }) }}
                  />
            </Col>
            <Col sm={2}>
              <Button type="reset" color="outline-primary" style={{ width:'120px', marginRight:'0' }}  >Clear fields</Button>
            </Col>
            <Col sm={2}>
              <Button type="submit" color="primary" block>Search</Button>
            </Col>
          </FormGroup>

          <Row>
            <p> <br></br></p>
          </Row>

        </form>
      </div>
    );
  }
}
