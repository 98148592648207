export function estraiSiglaAutore(stringaAutore) {
    if (stringaAutore === 'antonella.ghignoli@uniroma1.it') return 'AG'
    if (stringaAutore === 'anna.monte@uniroma1.it') return 'AM'
    if (stringaAutore === 'nina.sietis@uniroma1.it') return 'NS'
    if (stringaAutore === 'maria.boccuzzi@uniroma1.it') return 'MB'
    if (stringaAutore === 'mireboccuzzi@gmail.com') return 'MB'
    if (stringaAutore === 'livia.briasco@uniroma1.it') return 'LB'
    if (stringaAutore === 'aneta.skalec@uniroma1.it') return 'AS'
    if (stringaAutore === 'lorelei.vanderheyden@uniroma1.it') return 'LV'
    if (stringaAutore === 'marta.marucci@uniroma1.it') return 'MM'
    if (stringaAutore === 'luciaconsuelo.colella@uniroma1.it') return 'LC'
}