import React from "react";
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import { fab } from '@fortawesome/free-brands-svg-icons';
import { faArrowCircleRight, faBug, faEye, faExternalLinkSquareAlt, faFilter, faIdBadge, faLink, faMapMarkerAlt } from '@fortawesome/free-solid-svg-icons';
import { faGithub } from '@fortawesome/free-brands-svg-icons';
import MainTemplate from './components/mainLayout/MainTemplate';
import Home from './components/Home/Home';
import BrowserNotSupported from './components/Home/BrowserNotSupported';
import Intro from './components/Intro/Intro';
import { detect } from 'detect-browser';
import DocsList from "./components/Search/All/DocsList";
import GSList from "./components/Search/All/GSList";
import PList from "./components/Search/All/PList";
import ViewDocDetail from "./components/Search/ById/Documents/ViewDocDetail";
import SearchDocs from "./components/Search/SimpleSearch/Documents/SearchDocs";
import SearchGS from "./components/Search/SimpleSearch/GraphicSymbols/SearchGS";
import SearchP from "./components/Search/SimpleSearch/Persons/SearchP";
import ViewPersonDetail from "./components/Search/ById/Persons/ViewPersonDetail";
import ViewGSDetail from "./components/Search/ById/GraphicSymbols/ViewGSDetail";
import MapView from "./components/Map/MapView";
import DataExport from "./components/Api/Api";
import "./App.css"

const browser = detect();

const compatibility = {
  'ie': 10000,
  'opera-mini': 10000,
  'bb10': 10000,
  'edge': 14,
  'firefox': 39,
  'chrome': 42, // 42
  'safari': 11,
  'opera': 29,
  'android': 67,
}

let notSupported = false;
if (browser){
  const bName = browser.name;
  const bVersion = browser.version.split('.')[0];

  if ( Object.keys(compatibility).indexOf(bName) > -1 && bVersion < compatibility[bName] ) {
    notSupported = true;
  }
}

library.add(fas, fab, faArrowCircleRight, faBug, faEye, faExternalLinkSquareAlt, faFilter, faGithub, faIdBadge, faLink, faMapMarkerAlt);


export default function App () {

  if (notSupported){
    return <BrowserNotSupported browser={browser} compatibility={compatibility} />
  }

  return (
    <BrowserRouter basename="/">
      <Switch>
      
        <MainTemplate>
          <Route exact path='/' component={ Home } />
          <Route exact path='/home' component={ Home } />
          <Route exact path='/:table(graphicsymbols|documents|persons|casestudies)' component={Intro} />
          <Route exact path='/map' component={MapView} />
          <Route exact path='/api' component={DataExport} />
          
          <Route exact path='/search/documents' component={SearchDocs} />
          <Route exact path='/search/document/:id' component={ViewDocDetail} />
          <Route exact path='/search/documents/all' component={DocsList} />
          
          <Route exact path='/search/persons' component={SearchP} />
          <Route exact path='/search/person/:id' component={ViewPersonDetail} />
          <Route exact path='/search/persons/all' component={PList} />
          
          <Route exact path='/search/graphicsymbols' component={SearchGS} />
          <Route exact path='/search/graphicsymbols/all' component={GSList} />
          <Route exact path='/search/graphicsymbol/:id' component={ViewGSDetail} />
        </MainTemplate>

      </Switch>
    </BrowserRouter>
  );
  
}
