import React, { Component } from 'react';
import { Row, Col } from 'reactstrap';
import Select from 'react-select'
import FieldSet from '../FieldSet';
import {estraiSiglaAutore} from '../Utils/utils'


export default class DocumentBody extends Component {

  constructor(props) {
    super();
    this.state = {
      valHand: props.valHand
    }
  }

  _changeValSelect = (v) =>{
    this.setState({
      valHand: v.constructor === Object && v.value ? v.value : v
    });
  }

  changeStateValHands = (v) =>{
    this.setState({
      valHand: v
    });
  }
  

  render() {
    var document = this.props.doc;
    //console.log(document)

    // set up "document.origin"
    var documentOrigin = "";
    if (document.associatedLocationOrigin !== null){
      documentOrigin = documentOrigin + document.associatedLocationOrigin.name
      if (document.uncertainOriginAssignment === true){
        documentOrigin = documentOrigin + " (?)"
      }
      documentOrigin = "<p>" + documentOrigin + "</p>"
    }
    if (document.associatedLocationOrigin2 !== null){
      documentOrigin = documentOrigin + document.associatedLocationOrigin2.name
      if (document.uncertainOrigin2Assignment === true){
        documentOrigin = documentOrigin + " (?)"
      }
    }

    // set up "document.provenance"
    var documentProvenance = "";
    if (document.associatedLocationProvenance !== null){
      documentProvenance = documentProvenance + document.associatedLocationProvenance.name
      if (document.uncertainProvenanceAssignment === true){
        documentProvenance = documentProvenance + " (?)"
      }
      documentProvenance = "<p>" + documentProvenance + "</p>"
    }
    if (document.associatedLocationProvenance2 !== null){
      documentProvenance = documentProvenance + document.associatedLocationProvenance2.name
      if (document.uncertainProvenance2Assignment === true){
        documentProvenance = documentProvenance + " (?)"
      }
    }

    // set up "document.authors" considering also "other authors"
    var documentAuthors = "<p>" + estraiSiglaAutore(document.userCreation) + "</p>";
    if (document.otherAuthors !== ""){
      var others = document.otherAuthors.split("||^||").filter(Boolean);
      others.forEach(author => {
        documentAuthors = documentAuthors + "<p>" + estraiSiglaAutore(author) + "</p>"
      });
    }

    var psIds = new Set() //to avoid material supports' duplicates

    // set up bibliographies
    var docEditionsEx = ""
    document.editionsEx.forEach((edition, index) => {
      if (edition.zotero !== null){
        docEditionsEx = docEditionsEx + "<a href= "+ edition.zotero.replace('||^||', '').trim() +" target=\"_blank\" rel=\"noopener noreferrer\">"
                                      + "<FontAwesomeIcon icon=\"external-link-alt\" /> " + edition.referenceText +"</a>"
      } else {
        docEditionsEx = docEditionsEx + edition.referenceText
      }
      if (edition.citations[0].documentSpecificNumber !== null && edition.citations[0].documentSpecificNumber !== ''){
        docEditionsEx = docEditionsEx + ', number ' + edition.citations[0].documentSpecificNumber
      }
      if (edition.citations[0].documentSpecificPages !== null && edition.citations[0].documentSpecificPages !== ''){
        docEditionsEx = docEditionsEx + ', pages ' + edition.citations[0].documentSpecificPages
      }
      if (edition.citations[0].documentSpecificTome !== null && edition.citations[0].documentSpecificTome !== ''){
        docEditionsEx = docEditionsEx + ', tome ' + edition.citations[0].documentSpecificTome
      }
      docEditionsEx = docEditionsEx + '; '
      if (index===document.editionsEx.length-1){
        docEditionsEx = docEditionsEx.substring(0, docEditionsEx.length-2); //remove last semicolon from the list
      }
    });

    var docOtherIdentifiersEx = ""
    document.otherIdentifiersEx.forEach((other, index) => {
      if (other.zotero !== null){
        docOtherIdentifiersEx = docOtherIdentifiersEx + "<a href= "+ other.zotero.replace('||^||', '').trim() +" target=\"_blank\" rel=\"noopener noreferrer\">"
                                      + "<FontAwesomeIcon icon=\"external-link-alt\" /> " + other.referenceText +"</a>"
      } else {
        docOtherIdentifiersEx = docOtherIdentifiersEx + other.referenceText
      }
      if (other.citations[0].documentSpecificNumber !== null && other.citations[0].documentSpecificNumber !== ''){
        docOtherIdentifiersEx = docOtherIdentifiersEx + ', number ' + other.citations[0].documentSpecificNumber
      }
      if (other.citations[0].documentSpecificPages !== null && other.citations[0].documentSpecificPages !== ''){
        docOtherIdentifiersEx = docOtherIdentifiersEx + ', pages ' + other.citations[0].documentSpecificPages
      }
      docOtherIdentifiersEx = docOtherIdentifiersEx + '; '
      if (index===document.otherIdentifiersEx.length-1){
        docOtherIdentifiersEx = docOtherIdentifiersEx.substring(0, docOtherIdentifiersEx.length-2); //remove last semicolon from the list
      }
    });

    var docPrintedFacsimileEx = ""
    document.printedFacsimileEx.forEach((printedFs, index) => {
      if (printedFs.zotero !== null){
        docPrintedFacsimileEx = docPrintedFacsimileEx + "<a href= "+ printedFs.zotero.replace('||^||', '').trim() +" target=\"_blank\" rel=\"noopener noreferrer\">"
                                      + "<FontAwesomeIcon icon=\"external-link-alt\" /> " + printedFs.referenceText +"</a>"
      } else {
        docPrintedFacsimileEx = docPrintedFacsimileEx + printedFs.referenceText
      }
      if (printedFs.citations[0].documentSpecificNumber !== null && printedFs.citations[0].documentSpecificNumber !== ''){
        docPrintedFacsimileEx = docPrintedFacsimileEx + ', number ' + printedFs.citations[0].documentSpecificNumber
      }
      if (printedFs.citations[0].documentSpecificPages !== null && printedFs.citations[0].documentSpecificPages !== ''){
        docPrintedFacsimileEx = docPrintedFacsimileEx + ', pages ' + printedFs.citations[0].documentSpecificPages
      }
      if (printedFs.citations[0].documentSpecificTome !== null && printedFs.citations[0].documentSpecificTome !== ''){
        docPrintedFacsimileEx = docPrintedFacsimileEx + ', tome ' + printedFs.citations[0].documentSpecificTome
      }
      if (printedFs.citations[0].documentSpecificPlate !== null && printedFs.citations[0].documentSpecificPlate !== ''){
        docPrintedFacsimileEx = docPrintedFacsimileEx + ', plate ' + printedFs.citations[0].documentSpecificPlate
      }
      
      docPrintedFacsimileEx = docPrintedFacsimileEx + '; '
      if (index===document.printedFacsimileEx.length-1){
        docPrintedFacsimileEx = docPrintedFacsimileEx.substring(0, docPrintedFacsimileEx.length-2); //remove last semicolon from the list
      }
    });

    var docBibliographyEx = ""
    document.bibliographyEx.forEach((biblio, index) => {
      if (biblio.zotero !== null){
        docBibliographyEx = docBibliographyEx + "<a href= "+ biblio.zotero.replace('||^||', '').trim() +" target=\"_blank\" rel=\"noopener noreferrer\">"
                                      + "<FontAwesomeIcon icon=\"external-link-alt\" /> " + biblio.referenceText +"</a>"
      } else {
        docBibliographyEx = docBibliographyEx + biblio.referenceText
      }
      if (biblio.citations[0].documentSpecificNumber !== null && biblio.citations[0].documentSpecificNumber !== ''){
        docBibliographyEx = docBibliographyEx + ', number ' + biblio.citations[0].documentSpecificNumber
      }
      if (biblio.citations[0].documentSpecificPages !== null && biblio.citations[0].documentSpecificPages !== ''){
        docBibliographyEx = docBibliographyEx + ', pages ' + biblio.citations[0].documentSpecificPages
      }
      if (biblio.citations[0].documentSpecificTome !== null && biblio.citations[0].documentSpecificTome !== ''){
        docBibliographyEx = docBibliographyEx + ', tome ' + biblio.citations[0].documentSpecificTome
      }
      docBibliographyEx = docBibliographyEx + '; '
      if (index===document.bibliographyEx.length-1){
        docBibliographyEx = docBibliographyEx.substring(0, docBibliographyEx.length-2); //remove last semicolon from the list
      }                              
    });

    // set menu tendina Hands
    var optionsHands = [];
    var showHands = false;
    var counterHands = 1;
    
    if (document.hands.length>0){
      document.hands.forEach(hand => {
        optionsHands.push( { value: hand, label: "Hand "+counterHands })
        counterHands++;
      });
      //console.log(optionsHands)
      if(this.state.valHand===undefined){
        this.changeStateValHands(optionsHands[0].value)
      }
      showHands = true
    }

    // set up graphic symbols IDs
    var gssFromHands = ""
    document.gssIds.sort();
    document.gssIds.forEach((gsId, index) => {
      gssFromHands = gssFromHands + "<a href= \"/search/graphicsymbol/" + gsId + "\"  target=\"_blank\" rel=\"noopener noreferrer\">"
                                  + "<FontAwesomeIcon icon=\"external-link-alt\" /> GS " + gsId + "</a>; "
      if (index=== document.gssIds.length-1){
        gssFromHands = gssFromHands.substring(0, gssFromHands.length-2); //remove last semicolon from the list
      }
    });


    return (
      <div>
        <FieldSet legend="Document identifier" fields={[
          ["Main Identifier", document.mainIdentifier] // [NOME_CAMPO, VALORE]
        ]} />

        <FieldSet legend="Main Informations" fields={[
          ["Date", document.dateNotes],
          ["Origin(s)", documentOrigin],
          ["Provenance(s)", documentProvenance],
          ["Original Archive", document.originalArchive],
          ["Title", document.title],
          ["Subject", document.subject],
          ["Language", document.language]
        ]} />

        {document.physicalSupports.map((ps, k) => { //material support(s)
          if (!psIds.has(ps.id)){
            psIds.add(ps.id)
            return (<FieldSet key={k} legend="Material Support" fields={[
              ["City", ps.city],
              ["Conservation Place", ps.conservationPlace],
              ["Inventory", ps.inventory],
              ["Material", ps.material],
              ["Physical description", ps.physicalDescription],
              ["Height (mm)", ps.height],
              ["Width (mm)", ps.width],
              ["Comments", ps.comment]
            ]} />);
          }
        })}

        {//Hands
          showHands && this.state.valHand !== undefined &&
          <fieldset className="p-3 border border-white mt-3">
            <legend 
              style={{ fontSize: '1.3rem', 'fontWeight': 'bold', color:'#822333'}}>Hands</legend>

            <Select isSearchable={false}
              defaultValue={{ label: "Hand 1", value: this.state.valHand}}
              options={ optionsHands }
              onChange={ this._changeValSelect }/>
            
            <Row className={"border-bottom mt-2 mb-2 pb-1"}>
              <Col sm="4" md="3">
                <div><label className="font-weight-bold">ID</label></div>
              </Col>
              <Col>
                <div><label id="handIdTxt" dangerouslySetInnerHTML={{__html: this.state.valHand.id}}></label></div>
              </Col>
            </Row>
            <Row  className={"border-bottom mt-2 mb-2 pb-1"}>
              <Col sm="4" md="3">
                <div><label className="font-weight-bold">Position</label></div>
              </Col>
              <Col>
                <div><label id="handPositionTxt" dangerouslySetInnerHTML={{__html: this.state.valHand.position}}></label></div>
              </Col>
            </Row>
            <Row  className={"border-bottom mt-2 mb-2 pb-1"}>
              <Col sm="4" md="3">
                <div><label className="font-weight-bold">Person</label></div>
              </Col>
              <Col>
                <a href={ "/search/person/"+this.state.valHand.personId } target="_blank" rel="noopener noreferrer">
                  Person {this.state.valHand.personId}
                </a>
              </Col>
            </Row>
            <Row  className={"border-bottom mt-2 mb-2 pb-1"}>
              <Col sm="4" md="3">
                <div><label className="font-weight-bold">Role</label></div>
              </Col>
              <Col>
                <div><label id="handRoleTxt" dangerouslySetInnerHTML={{__html: this.state.valHand.role}}></label></div>
              </Col>
            </Row>
            <Row  className={"border-bottom mt-2 mb-2 pb-1"}>
              <Col sm="4" md="3">
                <div><label className="font-weight-bold">Script</label></div>
              </Col>
              <Col>
                <div><label id="handScriptTxt"  dangerouslySetInnerHTML={{__html: this.state.valHand.script}}></label></div>
              </Col>
            </Row>
            <Row  className={"border-bottom mt-2 mb-2 pb-1"}>
              <Col sm="4" md="3">
                <div><label className="font-weight-bold">Comments</label></div>
              </Col>
              <Col>
                <div><label id="handCommentTxt" dangerouslySetInnerHTML={{__html: this.state.valHand.comment}}></label></div>
              </Col>
            </Row>
          </fieldset>
        }

        <FieldSet legend="Bibliography" fields={[
          ["Other Identifiers", docOtherIdentifiersEx],
          ["Editions", docEditionsEx],
          ["Printed Facsimile",docPrintedFacsimileEx],
          ["Bibliography", docBibliographyEx]
        ]} />

        <FieldSet legend="Graphic Symbols" fields={[
          ["Presence of symbols", document.maybeSymbols],
          gssFromHands !== "" && ["Graphic symbols", gssFromHands]
        ]} />

        {//Document's Comments
          <fieldset className="p-3 border border-white mt-3">
            <legend style={{ fontSize: '1.3rem', 'fontWeight': 'bold', color:'#822333'}}>Comments</legend>
            <Row className={"border-bottom mt-2 mb-2 pb-1"}>
              <Col>
                <div><label dangerouslySetInnerHTML={{ __html: document.comment }}></label></div>
              </Col>
            </Row>
          </fieldset>
        }

        <FieldSet legend="Metadata" fields={[
          ["Author(s)", documentAuthors],
          // ["Status", document.state],
          ["Last Modified", document.monthLastUpdate.split('T')[0]]
        ]} />

      </div>
    );
  }
}