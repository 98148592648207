import  React  from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import DataTable from "react-data-table-component";
import { Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import SubHead from '../../SubHead/SubHead';
import { fetchDocuments } from '../actions';
import { faBold } from '@fortawesome/free-solid-svg-icons';


class DocsListPage extends React.Component {
	
	/* Fetch all documents on component load */
	componentDidMount() {
		this.props.fetchDocuments('', 'id', 'asc',  20 , 0);
	}

	render() {
		var documents = this.props.documents.result.documents
		//console.log(this.props.documents.result.documents)
		//console.log(this.props.documents.result.total)
		const totalRows = this.props.documents.result.total
		
		var columns = []
		var grid = null

		if (totalRows > 0) {

		columns = [
			{ selector: (row,i) => row.id, name: 'ID' , width: '90px', center: true},
			{ selector: (row,i) => row.mainIdentifier, name: 'Main Identifier', width: '250px', center: true},
			{ selector: (row,i) => row.dateNotes, name: 'Date', width: '200px' },
			{ selector: (row,i) => row.title, name: 'Title', width: '260px'  },
			{ selector: (row,i) => row.maybeSymbols, name: 'Presence of Symbols', width: '190px', center: true },
			{ cell: row => 
				<Button size="sm" color="primary" style={{ marginLeft: "auto", width: "70px" }} tag={Link} to={ '/search/document/' + [row.id].join('/') } target="_blank">
					Details <FontAwesomeIcon icon="fas fa-angle-double-right" /> 
				</Button>
				}
		];

		const dataTableStyle = {
			head: {
				style: {
					fontSize: '14px',
					fontWeight: "bold",
					color: '#822333',
				},
			},
			// headRow: {
			// 	style: {
			// 		backgroundColor: '#c9a0a7',
			// 		minHeight: '52px'
			// 	},
			// },
		};
		
		var curr_page = 1
		var pageLimit = 20

		const handlePageChange = page => {
			this.props.fetchDocuments('', 'id', 'asc',  20 , page - 1);
			curr_page = page;
		};
		
		const handlePerRowsChange = async (newPerPage, page) => {
			this.props.fetchDocuments('', 'id', 'asc',  newPerPage , page - 1);
			pageLimit = newPerPage;
		};

		grid = (<div>
					<div className="mb-3">
						Documents found: <span style={{color: "#822333", fontWeight: "bold"}}>
						{ totalRows }</span> 
					</div>
					<div style={{width: '100%' }}>
						<DataTable
							striped
							highlightOnHover
							style={"font-size:larger"}
							columns={columns}
							data={documents}
							pagination
							paginationServer
							paginationTotalRows={totalRows}
							paginationDefaultPage={curr_page}
							paginationPerPage={20}
							paginationRowsPerPageOptions={[5,20,50]}
							onChangeRowsPerPage={handlePerRowsChange}
							onChangePage={handlePageChange}
							customStyles={dataTableStyle}		
						/>
					</div>
				</div>
		)

		}
		const emptyMessage = (
			<div className='ui info message'> There are no documents</div>
		)
	

		return (
			<div >
				<SubHead tblabel={'Documents'} tb={'documents'} text="View all" />
				<div className='ui container'>
					{totalRows > 0 ? grid : emptyMessage}
				</div>
			</div>
		); 
	}
}

DocsListPage.propTypes = {
	documents: PropTypes.object.isRequired, 
	fetchDocuments: PropTypes.func.isRequired
}

function mapStateToProps(state) {
	//console.log(state)
	return {
		documents: state
	}
}

export default connect(mapStateToProps, { fetchDocuments })(DocsListPage);